import React, {useEffect, useState, useRef} from 'react';
import LoadingShimmerList from '../../shared/LoadingShimmerList';
import LoadingShimmer from '../../shared/LoadingShimmer';
import styles from './TableComponentCST.module.scss';
import {dummyFunction} from '../../utils/commonUtil';
import typeIcon from '../../../styles/images/category-skills-tags-selected-side-nav.svg';
import skillsIcon from '../../../styles/images/type_skill_cst.svg';
import tagsIcon from '../../../styles/images/type_tag_cst.svg';
import categoryIcon from '../../../styles/images/type_category_cst.svg';
import CSTcard from './components/CSTcard/CSTcard';
import {getTitleByLocale} from '../../utils/CategoriesSkillsTagsManagementUtils/CSTLandingPage';

const TableListComponentCST = (props) => {
  const {
    list,
    EmptyScreenComponent,
    isSearchFilterEmptyState,
    language,
    onClickAudienceGrp = dummyFunction,
    stickyHeader = false,
    drawerDetails = {},
    drawerOpen = false,
    headerColor = 'black',
    componentClass = '',
    small = false,
    drawerIdForBrokenLink = '',
    isLoading,
    isCardView,
    isListView,
    allLanguage,
    isOtherLocalesMoreThanThree
  } = props;
  //console.log(">>>> >>>> TableListComponentCST >>>> props",props);
  const [defaultList, setDefaultList] = useState([]);
  const [secondElementList, setSecondElementList] = useState([]);
  const [audienceGroupList, setAudienceGroupList] = useState([]);
  useEffect(() => {
    setDefaultList(list);
    setAudienceGroupList(list);
  }, [list]);

  useEffect(() => {
    getCalcWidth();
  }, [drawerOpen]);

  const getCalcWidth = (text) => {
    let calcWidth = {};
    if (text == 'tbl_data') {
      if (drawerOpen) {
        calcWidth = {
          width: `calc((100% - 35%) / ${allLanguage?.length})`,
          display: 'flex',
          order: 'initial',
        };
      } else {
        calcWidth = {
          width: `calc((100% - 32%) / ${allLanguage?.length})`,
          display: 'flex',
          order: 'initial',
        };
      }
    } else {
      if (drawerOpen) {
        calcWidth = {width: `calc((100% - 35%) / ${allLanguage?.length})`};
      } else {
        calcWidth = {width: `calc((100% - 32%) / ${allLanguage?.length})`};
      }
    }
    return calcWidth;
  };

  return (
    <React.Fragment>
      {audienceGroupList?.length > 0 && !isLoading ? (
        <div
          className={[
            styles.table_container,
            stickyHeader ? styles.sticky_header_user_grp : '',
            headerColor == 'white' ? styles.is_white_header : styles.is_drawer_open,
            componentClass == 'category_main_wrapper' ? styles.category_main_wrapper : '',
          ].join(' ')}
        >
          {isListView && (
            <div className={headerColor == 'white' ? styles.tbl_header_white : styles.tbl_header}>
              {/* <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}> */}
              <div
                className={[
                  headerColor == 'white'
                    ? [styles.tbl_head_white, styles.tag_icon].join(' ')
                    : [styles.tbl_head, styles.tag_icon].join(' '),
                  drawerOpen ? styles.drawer_open : '',
                ].join(' ')}
              >
                <img src={typeIcon} alt="tag icon" />
                <span className={styles.type_heading}>Type</span>
              </div>

              {allLanguage &&
                allLanguage.map((lang, key) => {
                  return (
                    <div
                      key={key}
                      className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}
                      style={getCalcWidth('tbl_header')}
                    >
                      {`${lang?.title}*`}
                    </div>
                  );
                })}

              <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
                Instances
              </div>
            </div>
          )}

          {isCardView ? (
            <div className={styles.gridView}>
              {audienceGroupList.map((cst, index) => {
                const localeMap = getTitleByLocale(cst.multilingualDetails);
                return (
                  <CSTcard
                    {...cst}
                    localeMap={localeMap}
                    clickHandler={(e) => onClickAudienceGrp(e, cst)}
                    key={cst.id}
                    drawerOpen={drawerOpen}
                    allLanguage={allLanguage}
                    isOtherLocalesMoreThanThree={isOtherLocalesMoreThanThree}
                  />
                );
              })}
            </div>
          ) : (
            <div className={styles.tbl_body}>
              {audienceGroupList?.map((ele, index) => {
                const localeMap = getTitleByLocale(ele.multilingualDetails);
                return (
                  <div className={[styles.tbl_row_wrapper].join(' ')} key={index}>
                    <div
                      id={ele?.id}
                      className={[
                        styles.tbl_row,
                        (ele?.id === drawerDetails?.id || ele?.id === drawerIdForBrokenLink) &&
                        drawerOpen
                          ? styles.selected
                          : '',
                      ].join(' ')}
                      onClick={(e) => onClickAudienceGrp(e, ele)}
                    >
                      <div
                        className={[
                          `${styles.tbl_data} ${drawerOpen ? styles.drawer_open : ''}`,
                        ].join(' ')}
                      >
                        <div className={[styles.inner_data, ''].join(' ')}>
                          <img
                            src={
                              ele.type === 'Tag'
                                ? tagsIcon
                                : ele.type === 'Skill'
                                ? skillsIcon
                                : categoryIcon
                            }
                            alt="type icon"
                          />
                          <div className={[styles.txt, styles.pr_5].join(' ')}>{ele?.type}</div>
                        </div>
                      </div>

                      {allLanguage &&
                        allLanguage.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={styles.tbl_data}
                              style={getCalcWidth('tbl_data')}
                            >
                              <div className={[styles.inner_data, ''].join(' ')}>
                                <div className={[styles.txt, styles.pr_5].join(' ')}>
                                  {localeMap[item?.locale]}
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      <div className={[styles.tbl_data].join(' ')}>
                        <div
                          className={styles.inner_data}
                          //    onClick={() => handleCoursesModal()}
                        >
                          <div className={styles.courses_icon} />
                          <div className={[styles.txt].join(' ')}>{ele?.coursesCount}</div>
                        </div>
                        <div
                          className={styles.inner_data}
                          //    onClick={() => handleCoursesModal()}
                        >
                          <div className={styles.learning_path_icon} />
                          <div className={[styles.txt].join(' ')}>{ele?.learningPathsCount}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : isSearchFilterEmptyState && !isLoading ? (
        <EmptyScreenComponent small={false} />
      ) : isLoading ? (
        <div
          className={[
            styles.table_container,
            stickyHeader ? styles.sticky_header_user_grp : '',
            headerColor == 'white' ? styles.is_white_header : styles.is_drawer_open,
            componentClass == 'category_main_wrapper' ? styles.category_main_wrapper : '',
          ].join(' ')}
        >
          {isListView && (
            <div className={headerColor == 'white' ? styles.tbl_header_white : styles.tbl_header}>
              <div
                className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}
              ></div>
              <div
                className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}
              ></div>
              <div
                className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}
              ></div>
              <div
                className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}
              ></div>
            </div>
          )}
          <div className={styles.tbl_body}>
            {isListView && (
              <LoadingShimmerList count={20} />
            )}
            {isCardView && (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <LoadingShimmer count={20} flex30={true} />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

TableListComponentCST.propTypes = {};

export default TableListComponentCST;
