import React, {useEffect, useState, useRef} from 'react';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import Header from '../shared/header';
import DeleteModal from '../audienceManagement/DeleteModal';
import styles from './categoriesSkillsTags.module.scss';
import {connect} from 'react-redux';
import TopBarComponenent from '../audienceManagement/TopBar';
import TableListComponentCST from './TableComponentCST';
import {
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setNotificationData,
  setDashboardNotificationCountApi,
  setNotificationListApi,
} from '../../redux/actions/uiActions';
import DrawerComponentCST from './DrawerCST';
import CreateNewCST from './CreateNewCST';
import {makeRequest} from '../utils/APIsUtils/httpsUtils';
import EmptyScreenComponent from '../shared/emptyScreen';
import {setErrorStateTemplate} from '../../redux/actions/uiActions';
import Notification from '../shared/notification';
import config from '../../config';
import {useHistory} from 'react-router-dom';
import {
  fetchCategorySkillsTags,
  fetchCategorySkillsTagsListWithParams,
  fetchCategorySkillsTagsDetails,
  handleSearchInput,
  handleFilterApplyNew,
  closeDrawerModal,
  handleOnClickEditCourse,
  handleOnClickEditLearningPath,
  handleCloseCST,
  handleCSTTranslationChange,
  refreshList,
  handleDeleteCST,
  selectedCategorySkillsTags,
  initialUseEffect,
  handleTranslationChange,
  handleEnglishTranslationChange,
  searchVariableUseEffect,
} from '../utils/CategoriesSkillsTagsManagementUtils/CSTLandingPage';

import {
  handleCSTCreatModelCloseOpen,
  handleEnglishTranslationCreateCST,
  handleSecondaryTranslationCreateCST,
  handleCreateNewCSTButtonSaveChanges,
} from '../utils/CategoriesSkillsTagsManagementUtils/CreatCST';

const CategoriesSkillsTagsManagementComponent = ({
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setErrorStateTemplate,
  notificationData,
  setNotificationData,
  setDashboardNotificationCountApi,
  setNotificationListApi,
}) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const [isDelete, setDelete] = useState(false);
  const [search, setSearch] = useState('');
  const [isDrawer, setDrawer] = useState(false);
  const [isOnline, setOnline] = useState(true);
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);
  const [categorySkillsTagsDetails, setCategorySkillsTagsDetails] = useState({});
  const [previousSelectedCSTId, setPreviousSelectedCSTId] = useState('');

  const [predictedSearch, setPredictedSearch] = useState([]);
  const [isDrawerAPIFailed, setDrawerAPIFailed] = useState(false);
  const [drawerIdForBrokenLink, setDrawerIdForBrokenLink] = useState('');
  const searchFontXS = '14px';

  // Landing Page Data List
  const [categorgyList, setCategorgyList] = useState([]);
  const [categorgyListCopy, setCategorgyListCopy] = useState([]);

  //card & Grid view
  const [isCardView, setIsCardView] = useState(false);
  const [isListView, setIsListView] = useState(false);
  const [isOtherLocalesMoreThanThree, setIsOtherLocalesMoreThanThree] = useState(false);
  const [isMarketApiSuccess, setIsMarketApiSuccess] = useState(false);

  // Primary and Seconddary Translation Name State
  const [translationNames, setTranslationNames] = useState({});
  const [translationNamesIntialCopy, setTranslationNamesIntialCopy] = useState({});

  const [coursesDrawerList, setCoursesDrawerList] = useState([]);
  const [learningPathsDrawerList, setLearningPathsDrawerList] = useState([]);
  const [isEditDrawer, setIsEditDrawer] = useState(false);
  const [isCreateDrawer, setIsCreateDrawer] = useState(false);

  // Loading States
  const [isLoadingCoursesList, setLoadingCoursesList] = useState(false);
  const [isLoadingLearningPathList, setLoadingLearningPathList] = useState(false);

  // Error states
  const [isEnglishTranslationEmpty, setEnglishTranslationEmpty] = useState(false);
  const [isSpanishTranslationEmpty, setSpanishTranslationEmpty] = useState(false);

  const [englishTranslationDuplicetName, setEnglishTranslationDuplicetName] = useState(false);
  const [nameErrorStateSpanish, setNameErrorStateSpanish] = useState(false);

  const [nameErrorStateEnglish, setNameErrorStateEnglish] = useState(false);

  // Invalid Char States for Drawer Input Field
  const [englishTranslationInvalidCharacter, setEnglishTranslationInvalidCharacter] = useState('');

  // Broken Link CST Drawer Name State
  const [selctedCST, setSelectedCST] = useState({});

  // Create CST States
  const [isCreateCSTOpen, setCreateCSTOpen] = useState(false);
  const [mouseHoverState, setMouseHoverState] = useState('Select type');
  const [selectTypeText, setSelectTypeText] = useState('Select type');
  const [openSelectTypeDropdown, setOpenSelectTypeDropdown] = useState(false);
  const [englishTranslationCreateCST, setEnglishTranslationCreateCST] = useState('');
  const [spanishTranslationCreateCST, setSpanishTranslationCreateCST] = useState('');

  // Dynamic CST
  const [secondaryTranslationCreateCST, setSecondaryTranslationCreateCST] = useState([]);

  const [isCreateSecondaryTranslationEmpty, setCreateSecondaryTranslationEmpty] = useState([]);
  const [CreateSecondaryTranslationLength, setCreateSecondaryTranslationLength] = useState([]);
  const [nameErrorStateSecondaryCreate, setNameErrorStateSecondaryCreate] = useState([]);
  const [CreateMaxCharErrorMessageSecondary, setCreateMaxCharErrorMessageSecondary] = useState([]);
  const [nameErrorStateSecondary, setNameErrorStateSecondary] = useState([]);
  const [
    secondaryTranslationCreateCSTInvalidCharacter,
    setSecondaryTranslationCreateCSTInvalidCharacter,
  ] = useState([]);

  // Invalid Char States for Drawer Input Field
  const [
    englishTranslationCreateCSTInvalidCharacter,
    setEnglishTranslationCreateCSTInvalidCharacter,
  ] = useState('');
  const [
    spanishTranslationCreateCSTInvalidCharacter,
    setSpanishTranslationCreateCSTInvalidCharacter,
  ] = useState('');

  // Create CST Error State
  // English
  const [isCreateEnglishTranslationEmpty, setCreateEnglishTranslationEmpty] = useState(false);
  const [CreateEnglishTranslationDuplicetName, setCreateEnglishTranslationDuplicetName] =
    useState(false);

  const [CreateEnglishTranslationLength, setCreateEnglishTranslationLength] = useState('');
  const [nameErrorStateEnglishCreate, setNameErrorStateEnglishCreate] = useState(false);
  const [CreateMaxCharErrorMessageEnglish, setCreateMaxCharErrorMessageEnglish] = useState(false);
  const [isCreateButtonDisabled, setCreateButtonDisabled] = useState(true);

  // Spanish
  const [isCreateSpanishTranslationEmpty, setCreateSpanishTranslationEmpty] = useState(false);
  const [CreateSpanishTranslationLength, setCreateSpanishTranslationLength] = useState('');
  const [nameErrorStateSpanishCreate, setNameErrorStateSpanishCreate] = useState(false);
  const [CreateMaxCharErrorMessageSpanish, setCreateMaxCharErrorMessageSpanish] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [createBtnEnabled, setCreateBtnEnabled] = useState(false);
  const [isCreateButtonEnable, setIsCreateButtonEnable] = useState(false);

  const regexNameEnglish = /[a-zA-Z0-9:： -]+/g;
  const regexNameChinese = /[\u4e00-\u9fa50-9:： -]+/g;
  const regexNameKorean = /[\u3131-\uD79D0-9a-zA-Z:： -]+/g;
  const regexNameFrench = /[\u00C0-\u00FF0-9a-zA-Z:： -]+/g;
  const regexNameSpanish = /[\u00C0-\u00FF0-9a-zA-Z:： -]+/g;

  const _initialFilterData = {
    tiles: [
      {
        index: 0,
        id: 'DRAFT',
        title: 'Categories',
        className: 'draft',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 1,
        id: 'LIVE',
        title: 'Skills',
        className: 'goLive',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 2,
        id: 'OFFLINE',
        title: 'Tags',
        className: 'goOffline',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
    ],
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 3,
              name: 'Newest To Oldest',
              id: 4,
              checked: false,
              filterParameter: 'launchDate',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 4,
              name: 'Oldest To Newest',
              id: 5,
              checked: false,
              filterParameter: 'launchDate',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
            },
          ],
        },
      },
    ],
  };

  useEffect(async () => {
    if (isMarketApiSuccess) {
      initialUseEffect({
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
        setSelectedNavigationOption,
        setIsUserLoggedIn,
        setIsLoading,
        isMarketApiSuccess,
        isOtherLocalesMoreThanThree
      });
    }
  }, [isMarketApiSuccess]);

  const [filterDataTemp, setFilterDataTemp] = useState(new Object(_initialFilterData));

  const [filterData, setFilterData] = useState(_initialFilterData);
  const [language, setLanguage] = useState({});
  const [inputData, setInputData] = useState([]);
  const [allLanguage, setAllLanguage] = useState([]);

  useEffect(() => {
    searchVariableUseEffect({
      search,
      categorgyListCopy,
      setCategorgyList,
    });
  }, [search, categorgyListCopy]);

  useEffect(async () => {
    await getLanguage();
  }, []);

  useEffect(() => {
    if (!isDrawer) {
      setPreviousSelectedCSTId('');
      setCreateMaxCharErrorMessageSecondary([]);
    }
  }, [isDrawer]);

  useEffect(() => {
    if (language !== undefined && Object.values(language)?.length > 0) {
      let tempArray = [];
      const marketDefaultLocale = language?.marketDefaultLocale;
      const otherLocales = language?.otherLocales;
      tempArray.push([marketDefaultLocale, ...otherLocales]);
      setAllLanguage(tempArray?.flat());
      const result = tempArray?.flat();
      result.map((item) => {
        setTranslationNames((prevState) => ({
          ...prevState,
          [`${item?.title?.toLowerCase()?.replace(/ .*/, '')}_Translation`]: '',
        }));
      })

      result.map((item) => {
        setTranslationNamesIntialCopy((prevState) => ({
          ...prevState,
          [`${item?.title?.toLowerCase()?.replace(/ .*/, '')}_Translation`]: '',
        }));
      })
    }
  }, [language]);

  const getLanguage = async () => {
    const apiData = await makeRequest({
      method: 'GET',
      endpoint: `/api/v1/market-languages`,
    });
    if (apiData) {
      setIsMarketApiSuccess(true);
      setLanguage(apiData?.data);
      apiData?.data?.otherLocales?.map((item, key) => {
        setCreateSecondaryTranslationEmpty((prevState) => ({
          ...prevState,
          [item?.title?.toLowerCase()?.replace(/ .*/, '')]: false,
        }));
        setNameErrorStateSecondaryCreate((prevState) => ({
          ...prevState,
          [item?.title?.toLowerCase()?.replace(/ .*/, '')]: false,
        }));
        setCreateMaxCharErrorMessageSecondary((prevState) => ({
          ...prevState,
          [item?.title?.toLowerCase()?.replace(/ .*/, '')]: false,
        }));
        setCreateSecondaryTranslationLength((prevState) => ({
          ...prevState,
          [item?.title?.toLowerCase()?.replace(/ .*/, '')]: '',
        }));
        setSecondaryTranslationCreateCST((prevState) => ({
          ...prevState,
          [item?.title]: {
            text: '',
            name: item?.title?.toLowerCase()?.replace(/ .*/, ''),
          },
        }));
      });
      if (apiData?.data?.otherLocales?.length > 3) {
        setIsOtherLocalesMoreThanThree(true);
        setIsCardView(true);
        setIsListView(false);
      } else {
        setIsCardView(false);
        setIsListView(true);
      }
    }
  };

  const saveAllInputs = (data) => {
    sessionStorage.setItem('CSTInputData', JSON.stringify(data));
    setInputData((prevState) => [prevState, { ...data }][1]);
  };

  return (
    <React.Fragment>
      <div className={[styles.audience_mgt_wrapper, styles.category_main_wrapper].join(' ')}>
        {/* Header Component */}
        <Header
          headerName="Categories, Skills, Tags Management"
          headerXSName=""
          btnName1="Cancel"
          btnName2="Save changes"
          isHeaderXS={false}
          isBtn1={false}
          isBtn2={false}
        />
        {/* Header Component */}
        {/* Learning Empty error container */}
        {/* {categorgyList?.length == 0 && (
          <div className={[styles.am_empty_screen_wrapper, ''].join(' ')}>
            <div className={styles.inner_wrapper_empty}>
              <div className={styles.empty_screen}>
                <div className={[styles.image_wrapper, styles.am_image].join(' ')}></div>

                <div className={styles.content_wrapper}>
                  <div className={styles.label}>Categories, Skills, Tags Management</div>
                  <div className={styles.content}>
                    Ability to target courses at specific groups of ABOs coming later in 2022.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {/* End of empty screen */}
        <div className={[styles.audience_wrapper].join(' ')}>
          <TopBarComponenent
            list={categorgyList}
            fetchList={async (params) => {
              await fetchCategorySkillsTags({
                setCategorgyList,
                setSearchFilterEmptyState,
                setCategorgyListCopy,
                setErrorStateTemplate,
                isOnline,
                isOtherLocalesMoreThanThree,
                isMarketApiSuccess
              });
            }}
            searchListFunction={(e) => {
              if (e != '') {
                setSearch(e.target.value);
              } else if (e == '') {
                setSearch('');
              }
            }}
            placeholderName={'Search Categories/Skills/Tags'}
            setPredictedSearch={setPredictedSearch}
            predictedSearch={predictedSearch}
            handleSearchInput={(event) => {
              handleSearchInput({
                event,
                setSearch,
                setPredictedSearch,
                categorgyList,
              });
            }}
            handleFilterApply={(params) => {
              handleFilterApplyNew({
                params,
                filterData,
                makeRequest,
                setFilterData,
                setFilterDataTemp,
                setCategorgyList,
                setCategorgyListCopy,
                setSearchFilterEmptyState,
                setErrorStateTemplate,
                setIsLoading,
              });
            }}
            filterData={filterData}
            addNewButton={() => {
              isCreateCSTOpen;
            }}
            addNewButtonText={'New'}
            searchFontXS={searchFontXS}
            isOpenSubCategories={true}
            componentCST={true}
            isDrawer={isDrawer}
            setDrawer={setDrawer}
            searchBarFrom={'CST'}
            filterPillAlign="center"
            isTilesBlue={true}
            newButtonHandle={(props) => {
              handleCSTCreatModelCloseOpen({
                isDrawer,
                setDrawer,
                setIsEditDrawer,
                setCreateCSTOpen,
                isCreateCSTOpen,
                setSelectTypeText,
                setOpenSelectTypeDropdown,
                setMouseHoverState,
                setEnglishTranslationCreateCST,
                setSpanishTranslationCreateCST,
                setSecondaryTranslationCreateCST,
                nameErrorStateEnglishCreate,
                setNameErrorStateEnglishCreate,
                setCreateEnglishTranslationDuplicetName,
                CreateEnglishTranslationDuplicetName,
                setCreateMaxCharErrorMessageEnglish,
                setCreateEnglishTranslationEmpty,
                setNameErrorStateSpanishCreate,
                setNameErrorStateSecondaryCreate,

                setCreateMaxCharErrorMessageSpanish,
                setCreateMaxCharErrorMessageSecondary,

                setCreateSpanishTranslationEmpty,
                setCreateSecondaryTranslationEmpty,

                language,
              });
            }}
            showGridListSwitch={language?.otherLocales?.length <= 3}
            setIsCardView={setIsCardView}
            setIsListView={setIsListView}
            isCardView={isCardView}
            isListView={isListView}
            marginLeft={language?.otherLocales?.length >= 4 ? 'auto' : ''}
            language={language}
          />

          <div className={styles.inner_wrapper}>
            <TableListComponentCST
              list={categorgyList}
              isLoading={isLoading}
              language={language}
              isOtherLocalesMoreThanThree={isOtherLocalesMoreThanThree}
              EmptyScreenComponent={EmptyScreenComponent}
              isSearchFilterEmptyState={isSearchFilterEmptyState}
              onClickAudienceGrp={(e, ele) => {
                if (ele?.id !== previousSelectedCSTId) {
                  selectedCategorySkillsTags({
                    e,
                    item: ele?.id,
                    isEditDrawer,
                    setIsEditDrawer,
                    isDrawer,
                    categorySkillsTagsDetails,
                    setDrawer,
                    setCategorySkillsTagsDetails,
                    makeRequest,
                    setDrawerAPIFailed,
                    drawerIdForBrokenLink,
                    setDrawerIdForBrokenLink,
                    type: ele?.type,
                    setTranslationNames,
                    setCoursesDrawerList,
                    setLearningPathsDrawerList,
                    englishTranslationDuplicetName,
                    coursesDrawerList,
                    learningPathsDrawerList,
                    setSelectedCST,
                    translationNames,
                    translationNamesIntialCopy,
                    setEnglishTranslationEmpty,
                    setSpanishTranslationEmpty,
                    setCategorgyList,
                    setSearchFilterEmptyState,
                    setCategorgyListCopy,
                    setErrorStateTemplate,
                    isOnline,
                    setEnglishTranslationDuplicetName,
                    setNotificationData,
                    setDashboardNotificationCountApi,
                    setNotificationListApi,
                    regexNameChinese,
                    regexNameKorean,
                    regexNameFrench,
                    regexNameSpanish,
                    setTranslationNamesIntialCopy,
                    filterData,
                    nameErrorStateSpanish,
                    nameErrorStateSecondary,
                    nameErrorStateEnglish,
                    language,
                    setCreateMaxCharErrorMessageSecondary,
                  });
                  setPreviousSelectedCSTId(ele?.id);
                }
              }}
              componentClass="category_main_wrapper"
              drawerDetails={categorySkillsTagsDetails}
              drawerIdForBrokenLink={drawerIdForBrokenLink}
              drawerOpen={isDrawer}
              drawerClose={setDrawer}
              isCardView={isCardView}
              isListView={isListView}
              allLanguage={allLanguage}
            />

            <DrawerComponentCST
              isEditDrawer={isEditDrawer}
              drawerDetails={categorySkillsTagsDetails}
              drawerOpen={isDrawer}
              drawerClose={setDrawer}
              language={language}
              isAPIFailed={isDrawerAPIFailed}
              drawerTryAgainCall={(drawerId) => {
                fetchCategorySkillsTagsDetails({
                  CSTId: drawerId,
                  makeRequest,
                  setCategorySkillsTagsDetails,
                  setDrawer,
                  setDrawerAPIFailed,
                  drawerIdForBrokenLink,
                  setDrawerIdForBrokenLink,
                  setTranslationNames,
                  setCoursesDrawerList,
                  setLearningPathsDrawerList,
                  setTranslationNamesIntialCopy,
                  learningPathsDrawerList,
                  coursesDrawerList,
                  setCategorgyList,
                  setSearchFilterEmptyState,
                  setCategorgyListCopy,
                  setErrorStateTemplate,
                  isOnline,
                  type: selctedCST,
                  language: language,
                });
              }}
              drawerIdForBrokenLink={drawerIdForBrokenLink}
              translationNames={translationNames}
              handleEnglishTranslationChange={(props) => {
                handleEnglishTranslationChange({
                  e: props?.e,
                  variant: props?.variant,
                  currentKey: props?.currentKey,
                  setTranslationNames,
                  translationNames,
                  regexNameEnglish,
                });
              }}
              handleTranslationChange={(props) => {
                handleTranslationChange({
                  e: props?.e,
                  variant: props?.variant,
                  currentKey: props?.currentKey,
                  setTranslationNames,
                  translationNames,
                });
              }}
              refreshList={(props) => {
                refreshList({
                  variant: props?.variant,
                  drawerId: props?.drawerId,
                  type: props?.type,
                  setLoadingCoursesList,
                  setLoadingLearningPathList,
                  setCategorySkillsTagsDetails,
                  setDrawer,
                  setDrawerAPIFailed,
                  drawerIdForBrokenLink,
                  setDrawerIdForBrokenLink,
                  setTranslationNames,
                  setCoursesDrawerList,
                  setLearningPathsDrawerList,
                  setTranslationNamesIntialCopy,
                  learningPathsDrawerList,
                  coursesDrawerList,
                  setCategorgyList,
                  setSearchFilterEmptyState,
                  setCategorgyListCopy,
                  setErrorStateTemplate,
                  isOnline,
                  language: language,
                });
              }}
              coursesDrawerList={coursesDrawerList}
              learningPathsDrawerList={learningPathsDrawerList}
              isLoadingCoursesList={isLoadingCoursesList}
              isLoadingLearningPathList={isLoadingLearningPathList}
              handleCSTTranslationChange={(props) => {
                handleCSTTranslationChange({
                  type: props?.type,
                  CSTId: props?.CSTId,
                  translationNames,
                  setNotificationData,
                  translationNamesIntialCopy,
                  setDashboardNotificationCountApi,
                  setNotificationListApi,
                });
              }}
              closeDrawerModal={(props) => {
                closeDrawerModal({
                  englishTranslationLength: props?.englishTranslationLength,
                  drawerClose: props?.drawerClose,
                  drawerOpen: props?.drawerOpen,
                  drawerDetails: props?.drawerDetails,
                  setEnglishTranslationEmpty,
                  setCategorgyList,
                  setSearchFilterEmptyState,
                  setCategorgyListCopy,
                  setErrorStateTemplate,
                  isOnline,
                  handleCSTTranslationChange,
                  translationNamesIntialCopy,
                  translationNames,
                  setEnglishTranslationDuplicetName,
                  englishTranslationDuplicetName,
                  setNotificationData,
                  setDashboardNotificationCountApi,
                  setNotificationListApi,
                  filterData,
                  nameErrorStateSecondary,
                  nameErrorStateEnglish,
                  isSave: props?.isSave,
                  allLanguage: allLanguage,
                });
              }}
              isSpanishTranslationEmpty={isSpanishTranslationEmpty}
              isEnglishTranslationEmpty={isEnglishTranslationEmpty}
              setSpanishTranslationEmpty={setSpanishTranslationEmpty}
              setEnglishTranslationEmpty={setEnglishTranslationEmpty}
              translationNamesIntialCopy={translationNamesIntialCopy}
              handleCloseCST={() => {
                handleCloseCST({setDelete, isDelete});
              }}
              handleOnClickEditCourse={(props) => {
                handleOnClickEditCourse(props);
              }}
              handleOnClickEditLearningPath={(props) => {
                handleOnClickEditLearningPath({id: props?.id, state: props?.state});
              }}
              list={categorgyList}
              setEnglishTranslationDuplicetName={setEnglishTranslationDuplicetName}
              englishTranslationDuplicetName={englishTranslationDuplicetName}
              selctedCST={selctedCST}
              nameErrorStateSpanish={nameErrorStateSpanish}
              nameErrorStateSecondary={nameErrorStateSecondary}
              setNameErrorStateSpanish={setNameErrorStateSpanish}
              setNameErrorStateSecondary={setNameErrorStateSecondary}
              nameErrorStateEnglish={nameErrorStateEnglish}
              setNameErrorStateEnglish={setNameErrorStateEnglish}
              englishTranslationInvalidCharacter={englishTranslationInvalidCharacter}
              setEnglishTranslationInvalidCharacter={setEnglishTranslationInvalidCharacter}
              CreateMaxCharErrorMessageSecondary={CreateMaxCharErrorMessageSecondary}
              setNotificationData={setNotificationData}
              //-----------------------------
              //createNewCSTModal
              //---------------------------
              setEnglishTranslationCreateCSTInvalidCharacter={
                setEnglishTranslationCreateCSTInvalidCharacter
              }
              setSpanishTranslationCreateCSTInvalidCharacter={
                setSpanishTranslationCreateCSTInvalidCharacter
              }
              setSecondaryTranslationCreateCSTInvalidCharacter={
                setSecondaryTranslationCreateCSTInvalidCharacter
              }
              englishTranslationCreateCSTInvalidCharacter={
                englishTranslationCreateCSTInvalidCharacter
              }
              spanishTranslationCreateCSTInvalidCharacter={
                spanishTranslationCreateCSTInvalidCharacter
              }
              secondaryTranslationCreateCSTInvalidCharacter={
                secondaryTranslationCreateCSTInvalidCharacter
              }
              isCreateEnglishTranslationEmpty={isCreateEnglishTranslationEmpty}
              setCreateEnglishTranslationEmpty={setCreateEnglishTranslationEmpty}
              isCreateSpanishTranslationEmpty={isCreateSpanishTranslationEmpty}
              isCreateSecondaryTranslationEmpty={isCreateSecondaryTranslationEmpty}
              setCreateSpanishTranslationEmpty={setCreateSpanishTranslationEmpty}
              setCreateSecondaryTranslationEmpty={setCreateSecondaryTranslationEmpty}
              CreateEnglishTranslationDuplicetName={CreateEnglishTranslationDuplicetName}
              setCreateEnglishTranslationDuplicetName={setCreateEnglishTranslationDuplicetName}
              CreateMaxCharErrorMessageSpanish={CreateMaxCharErrorMessageSpanish}
              setCreateMaxCharErrorMessageSpanish={setCreateMaxCharErrorMessageSpanish}
              setCreateMaxCharErrorMessageSecondary={setCreateMaxCharErrorMessageSecondary}
              CreateMaxCharErrorMessageEnglish={CreateMaxCharErrorMessageEnglish}
              setCreateMaxCharErrorMessageEnglish={setCreateMaxCharErrorMessageEnglish}
              setNameErrorStateSpanishCreate={setNameErrorStateSpanishCreate}
              setNameErrorStateSecondaryCreate={setNameErrorStateSecondaryCreate}
              nameErrorStateSpanishCreate={nameErrorStateSpanishCreate}
              nameErrorStateSecondaryCreate={nameErrorStateSecondaryCreate}
              setNameErrorStateEnglishCreate={setNameErrorStateEnglishCreate}
              nameErrorStateEnglishCreate={nameErrorStateEnglishCreate}
              CreateSpanishTranslationLength={CreateSpanishTranslationLength}
              CreateSecondaryTranslationLength={CreateSecondaryTranslationLength}
              setCreateSpanishTranslationLength={setCreateSpanishTranslationLength}
              setCreateSecondaryTranslationLength={setCreateSecondaryTranslationLength}
              CreateEnglishTranslationLength={CreateEnglishTranslationLength}
              setCreateEnglishTranslationLength={setCreateEnglishTranslationLength}
              openAndCloseCST={isCreateCSTOpen}
              handleCSTCreatModelCloseOpen={() => {
                handleCSTCreatModelCloseOpen({
                  isDrawer,
                  setDrawer,
                  setIsEditDrawer,
                  setCreateCSTOpen,
                  isCreateCSTOpen,
                  setSelectTypeText,
                  setOpenSelectTypeDropdown,
                  setMouseHoverState,

                  setEnglishTranslationCreateCST,
                  setSpanishTranslationCreateCST,
                  setSecondaryTranslationCreateCST,

                  setNameErrorStateEnglishCreate,
                  setNameErrorStateSpanishCreate,
                  setNameErrorStateSecondaryCreate,

                  setCreateMaxCharErrorMessageEnglish,
                  setCreateEnglishTranslationDuplicetName,

                  setCreateSpanishTranslationEmpty,
                  setCreateSecondaryTranslationEmpty,
                  setCreateEnglishTranslationEmpty,

                  setCreateMaxCharErrorMessageSpanish,
                  setCreateMaxCharErrorMessageSecondary,
                });
              }}
              mouseHoverState={mouseHoverState}
              setMouseHoverState={setMouseHoverState}
              selectTypeText={selectTypeText}
              setSelectTypeText={setSelectTypeText}
              openSelectTypeDropdown={openSelectTypeDropdown}
              setOpenSelectTypeDropdown={setOpenSelectTypeDropdown}
              handleEnglishTranslationCreateCST={(props) => {
                handleEnglishTranslationCreateCST({
                  text: props?.text,
                  setEnglishTranslationCreateCST,
                  regexNameChinese: props?.regexNameChinese,
                  regexNameChinese2: props?.regexNameChinese2,
                });
              }}
              handleSecondaryTranslationCreateCST={(props) => {
                handleSecondaryTranslationCreateCST({
                  text: props?.text,
                  name: props?.name,
                  setSecondaryTranslationCreateCST,
                });
              }}
              englishTranslationCreateCST={englishTranslationCreateCST}
              spanishTranslationCreateCST={spanishTranslationCreateCST}
              secondaryTranslationCreateCST={secondaryTranslationCreateCST}
              createBtnEnabled={createBtnEnabled}
              setCreateBtnEnabled={setCreateBtnEnabled}
              isCreateButtonEnable={isCreateButtonEnable}
              setIsCreateButtonEnable={setIsCreateButtonEnable}
              handleCreateNewCSTButtonSaveChanges={(props) => {
                handleCreateNewCSTButtonSaveChanges({
                  setIsCreateButtonEnable,
                  setCreateBtnEnabled,
                  isDrawer,
                  setDrawer,
                  setIsEditDrawer,
                  selectTypeText,
                  englishTranslationCreateCST,
                  secondaryTranslationCreateCST,
                  setCategorgyList,
                  setSearchFilterEmptyState,
                  setCategorgyListCopy,
                  setErrorStateTemplate,
                  isOnline,
                  setCreateCSTOpen,
                  isCreateCSTOpen,
                  setSelectTypeText,
                  setOpenSelectTypeDropdown,
                  setMouseHoverState,
                  setEnglishTranslationCreateCST,
                  setSecondaryTranslationCreateCST,
                  nameErrorStateEnglishCreate,
                  setNameErrorStateEnglishCreate,
                  setCreateEnglishTranslationDuplicetName,
                  CreateEnglishTranslationDuplicetName,
                  nameErrorStateSecondaryCreate,
                  setNameErrorStateSecondaryCreate,
                  setCreateMaxCharErrorMessageEnglish,
                  setCreateMaxCharErrorMessageSecondary,
                  setNotificationData,
                  setDashboardNotificationCountApi,
                  setNotificationListApi,
                  fetchCategorySkillsTags,
                  setCreateSecondaryTranslationEmpty,
                  setCreateEnglishTranslationEmpty,
                  filterData: _initialFilterData,
                  setFilterData,
                  setIsLoading,
                  language,
                });
              }}
            />
          </div>
        </div>

        {/* Delete Modal */}
        {isDelete ? (
          <DeleteModal
            cstDelete={true}
            type={`${categorySkillsTagsDetails?.type}`}
            name={`the ${categorySkillsTagsDetails?.type}`}
            boldTxt={`${categorySkillsTagsDetails?.englishTranslation}`}
            closeDeleteModal={() => {
              handleCloseCST({setDelete, isDelete});
            }}
            deleteCall={() => {
              handleDeleteCST({
                categorySkillsTagsDetails,
                setDrawer,
                setNotificationData,
                setCategorgyList,
                setSearchFilterEmptyState,
                setCategorgyListCopy,
                setErrorStateTemplate,
                isOnline,
                setNotificationListApi,
                setDashboardNotificationCountApi,
                filterData,
              });
            }}
          />
        ) : null}
        {/* Delete Modal */}
      </div>

      {notificationData?.show && <Notification />}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isUserLoggedIn: state && state.uiState && state.uiState.isUserLoggedIn,
  notificationData: state && state.uiState && state.uiState.notificationData,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(CategoriesSkillsTagsManagementComponent),
);
