import { useHistory } from 'react-router-dom';
import LoadingShimmer from '../../shared/LoadingShimmer';
import LoadingShimmerList from '../../shared/LoadingShimmerList';
import StatusLabel from '../../shared/StatusLabel';
import React, { useState, useEffect } from 'react';
import {
  drawerCalculatedHeight,
  drawerOverlayCalculatedHeight,
  mobDrawerToOrignalState,
  pullbox,
} from '../commonUtil';
import { setMainImageBackup } from '../../../redux/actions/imageCrop/imageCropAction';
import { setNewThumbnailId } from '../../../redux/actions/newCourseActions';
import table_sorting_icon from '../../../styles/images/table_sorting_icon.svg';

export const fetchLearningPathList = async (props) => {
  const configData = JSON.parse(localStorage.getItem('configData'));

  const {
    makeRequest,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyState,
    isOnline,
    setErrorStateTemplate,
    setAllLearningPathList = () => { }
  } = props;
  let data = await makeRequest({
    method: 'GET',
    endpoint: `/admin/api/v1/learning-paths?locale=${configData?.locale}`,
  });
  if (data != 'ERR_NETWORK' && data) {
    setLearningPathAPIResponse(data?.data);
    setLearningPathAPIResponseCopy(data?.data);
    setAllLearningPathList(data?.data);
    setSearchFilterEmptyState(true);
  } else if (data == 'ERR_NETWORK' && isOnline) {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
  }
};

export const fetchLearningPathDetails = async (props) => {
  const configData = JSON.parse(localStorage.getItem('configData'));

  const {
    item,
    makeRequest,
    setLearningPathDetailsDrawerResponse,
    setDrawerAPIFailed,
    setDrawerIdForBrokenLink,
    drawerIdForBrokenLink,
  } = props;
  let endpoint = `/admin/api/v1/learning-paths/${item}?locale=${configData?.locale}`;
  const dataLearningPath = await makeRequest({
    method: 'GET',
    endpoint,
  });
  if (dataLearningPath != 'ERR_NETWORK' && dataLearningPath) {
    setLearningPathDetailsDrawerResponse(dataLearningPath?.data);
    setDrawerAPIFailed(false);
  } else if (dataLearningPath == 'ERR_NETWORK') {
    if (item != undefined && item !== drawerIdForBrokenLink) {
      setDrawerIdForBrokenLink(item);
    }
    setDrawerAPIFailed(true);
  }
};

export const fetchCoursesListWithParams = async (props) => {
  const {
    bodyVar,
    sortBy,
    tilesFilterArray,
    makeRequest,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
  } = props;
  // console.log(">>>> >>>> fetchCoursesListWithParams >>>> props",props);
  let endpoint = '/admin/api/v1/learning-paths';
  if (sortBy) {
    endpoint += `?sortBy=${sortBy?.toLowerCase()}&categoryIds=${bodyVar?.categoryId?.toString()}`;
  }
  const dataCourses = await makeRequest({
    method: 'GET',
    endpoint,
  });
  if (dataCourses != 'ERR_NETWORK' && dataCourses) {
    let newDataCourses = [];
    newDataCourses = dataCourses?.data;
    let tilesLables = [];
    if (tilesFilterArray !== []) {
      for (const ele of tilesFilterArray) {
        if (ele?.checked) {
          tilesLables.push(ele?.title);
        }
      }
      if (tilesLables?.length > 0) {
        newDataCourses = dataCourses?.data.filter((ele) => {
          return tilesLables.includes(ele?.state);
        });
      }
    }
    setLearningPathAPIResponse(newDataCourses);
    setLearningPathAPIResponseCopy(newDataCourses);
    setSearchFilterEmptyState(true);
  } else if (dataCourses == 'ERR_NETWORK') {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
  }
};

export const fetchCategories = async (props) => {
  const { makeRequest, filterData, findIndex, setFilterData } = props;
  const dataCategories = await makeRequest({
    method: 'GET',
    endpoint: '/api/v1/categories',
  });
  if (dataCategories) {
    let _filterData = filterData;
    let _filtersList = _filterData.filtersList;
    let _tempFiltersList = [];
    let index = findIndex(_filtersList, ['filterType', 'CHECKBOX']);
    if (index !== -1) {
      dataCategories?.data?.length &&
        dataCategories?.data?.length > 0 &&
        dataCategories?.data?.map((dataItem, _index) => {
          const { id = '', title = '' } = dataItem;
          _tempFiltersList.push({
            index: _index,
            id: id,
            label: title,
            title: title,
            filterParameter: 'category',
            checked: false,
            typeOfFilter: 'CHECKBOX',
          });
        });
      _filtersList[index].filterTypeData.sectionData = _tempFiltersList;
    }
    setFilterData(_filterData);
  }
};

export const closeDrawerModal = (props) => {
  const { setDrawer, setSelectedLearningPath, isDrawer } = props;
  setDrawer(!isDrawer);
  setSelectedLearningPath('');
};

export const getCourseButtonStatus = (props) => {
  const { learningPathDetailsDrawerResponse } = props;
  const status = learningPathDetailsDrawerResponse?.state?.toString()?.toLowerCase();
  return status === 'live'
    ? 'Go Offline'
    : status === 'offline'
      ? 'Go Live'
      : 'Delete Learning Path';
};

export const changeCourseStatus = async (props) => {
  const {
    status,
    makeRequest,
    learningPathDetailsDrawerResponse,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyState,
    isOnline,
    setErrorStateTemplate,
    fetchLearningPathDetails,
    setLearningPathDetailsDrawerResponse,
    setDrawerAPIFailed,
    setDrawerIdForBrokenLink,
    drawerIdForBrokenLink,
    setNotificationListApi,
    setDashboardNotificationCountApi,
    currentUserId,
    setNotificationData,
    oktaToken,
    setAllLearningPathList
  } = props;
  const dataCourses = await makeRequest({
    method: 'PATCH',
    endpoint: `/admin/api/v1/learning-paths/${learningPathDetailsDrawerResponse?.learningPathId}/${status}`,
  });
  if (dataCourses) {
    status == 'live' &&
      setNotificationData({
        show: true,
        title: 'Learning Path turned Live',
        description: `${learningPathDetailsDrawerResponse?.primaryTitle} has been turned live by the ${oktaToken.idToken.claims.fullName}`,
        type: 'SUCCESS',
      });
    status == 'offline' &&
      setNotificationData({
        show: true,
        title: 'Learning Path turned Offline',
        description: `${learningPathDetailsDrawerResponse?.primaryTitle} has been turned offline by the ${oktaToken.idToken.claims.fullName}`,
        type: 'SUCCESS',
      });
    fetchLearningPathList({
      makeRequest,
      setLearningPathAPIResponse,
      setLearningPathAPIResponseCopy,
      setSearchFilterEmptyState,
      isOnline,
      setErrorStateTemplate,
      setAllLearningPathList
    });
    fetchLearningPathDetails({
      item: learningPathDetailsDrawerResponse?.learningPathId,
      makeRequest,
      setLearningPathDetailsDrawerResponse,
      setDrawerAPIFailed,
      setDrawerIdForBrokenLink,
      drawerIdForBrokenLink,
    });
    setNotificationListApi();
    setDashboardNotificationCountApi(currentUserId);
  }
  // setNotificationListApi(currentUserId);
  // setDashboardNotificationCountApi(currentUserId);
};

export const OnClickOfArchiveLP = (props) => {
  const { setNotificationData, learningPathDetailsDrawerResponse } = props;
  setNotificationData({
    show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
    title: 'Learning Path Archived.',
    description: `${learningPathDetailsDrawerResponse?.primaryTitle} has been archived and cannot be edited.`,
    type: 'WARNING',
  });
};

export const handleSearchInput = (props) => {
  const { event, setSearch, setPredictedSearch, learningPathAPIResponse } = props;
  const searchQuery = event?.target?.value?.toLowerCase() || '';
  setSearch(searchQuery);
  setPredictedSearch([]);

  const tempPredition = learningPathAPIResponse
    ?.filter(item =>
    (item?.title?.toLowerCase().includes(searchQuery) ||
      item?.contentCode?.toLowerCase().includes(searchQuery))
    )
    ?.map(item => ({
      id:
        item?.learningPathId && item?.learningPathId !== null && item.learningPathId !== undefined
          ? item?.learningPathId
          : '',
      label: item?.title && item?.title !== null && item.title !== undefined ? item?.title : '',
    }));

  if (tempPredition.length >= 0 && event?.target?.value?.length >= 0) {
    setPredictedSearch(tempPredition ? tempPredition : {});
  } else {
    setPredictedSearch([]);
  }
};

export const selectedAudiGrp = async (props) => {
  const {
    e,
    item,
    isDrawer,
    learningPathDetailsDrawerResponse,
    setDrawer,
    setSelectedLearningPath,
    makeRequest,
    setLearningPathDetailsDrawerResponse,
    setDrawerAPIFailed,
    setDrawerIdForBrokenLink,
    drawerIdForBrokenLink,
    fetchLearningPathDetails,
  } = props;
  if (isDrawer && e.currentTarget.id === learningPathDetailsDrawerResponse?.learningPathId) {
    setDrawer(false);
    setSelectedLearningPath(false);
  } else {
    await fetchLearningPathDetails({
      item,
      makeRequest,
      setLearningPathDetailsDrawerResponse,
      setDrawerAPIFailed,
      setDrawerIdForBrokenLink,
      drawerIdForBrokenLink,
    });
    setSelectedLearningPath(item);
    setDrawer(true);
    var element = document.getElementById(item);
    element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
  }
};

export const handleDeleteModal = (props) => {
  const { setDelete, isDelete } = props;
  setDelete(!isDelete);
};

export const handleClickOutside = (props) => {
  const { e, setDrawer, setSelectedLearningPath, isDrawer } = props;
  const el = document.getElementById('divParentOfDrawer');
  if (el !== null) {
    const outside = el?.contains(e.target);
    if (outside) {
      if (e.target.className.includes('LearningPath_custom_overlay')) {
        closeDrawerModal({
          setDrawer,
          setSelectedLearningPath,
          isDrawer,
        });
      }
    }
  }
};

export const deleteLearningPath = async (props) => {
  const {
    learningPathDetailsDrawerResponse,
    makeRequest,
    setNotificationData,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyState,
    isOnline,
    setErrorStateTemplate,
    setDrawer,
    filterData,
  } = props;
  const categoryId = [];
  let categoriesArray = filterData?.filtersList[1]?.filterTypeData?.sectionData?.filter(
    (ele) => ele.typeOfFilter === 'CHECKBOX',
  );
  for (const ele of categoriesArray) {
    ele.checked && categoryId.push(ele.id);
  }

  let sortBy = '';
  let radioFilterArray = filterData?.filtersList[0]?.filterTypeData?.sectionData?.filter(
    (ele) => ele.checked === true,
  );
  radioFilterArray.forEach((ele) => {
    switch (ele?.name) {
      case 'Recent Activity':
        sortBy = 'Recent_Activity';
        break;
      case 'A - Z':
        sortBy = 'A_Z';
        break;
      case 'Z - A':
        sortBy = 'Z_A';
        break;
      case 'Newest To Oldest':
        sortBy = 'Newest_To_Oldest';
        break;
      case 'Oldest To Newest':
        sortBy = 'Oldest_To_Newest';
        break;
      default:
        sortBy = 'Recent_Activity';
        break;
    }
  });
  let deleteAudGrpIds = learningPathDetailsDrawerResponse?.audienceGroups?.map(({ id, type }) => {
    return { audGrpId: id, learningPathType: type };
  });

  let audienceGroups = {
    deleteAudGrpIds,
  };

  let tempObj = {
    audienceGroups,
  };

  let endpoint = `/admin/api/v1/learning-paths/${learningPathDetailsDrawerResponse?.learningPathId}`;
  let dataDeleteLearningPath = await makeRequest({
    method: 'DELETE',
    endpoint: endpoint,
    body: tempObj,
  });
  if (dataDeleteLearningPath?.status == 'Success') {
    setNotificationData({
      show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
      title: 'Learning Path Deleted',
      description: `${learningPathDetailsDrawerResponse?.primaryTitle} which was in Draft state has been deleted.`,
      type: 'SUCCESS',
    });
    await fetchCoursesListWithParams({
      bodyVar: { categoryId },
      sortBy,
      tilesFilterArray: filterData.tiles,
      makeRequest,
      setLearningPathAPIResponse,
      setLearningPathAPIResponseCopy,
      setSearchFilterEmptyState,
      setErrorStateTemplate,
    });
    setDrawer(false);
  }
  else {
    setNotificationData({
      show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
      title: 'Something went wrong',
      description: `Please try again`,
      type: 'FAILURE',
    });
  }
  // fetchLearningPathList({
  //   makeRequest,
  //   setLearningPathAPIResponse,
  //   setLearningPathAPIResponseCopy,
  //   setSearchFilterEmptyState,
  //   isOnline,
  //   setErrorStateTemplate,
  // });

};

export const handleFilterApplyNew = async (props) => {
  const {
    params,
    filterData,
    makeRequest,
    setFilterData,
    setFilterDataTemp,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    setIsLoading,
  } = props;
  setIsLoading(true);
  let tilesFilterArray = params.filter((ele) => ele.typeOfFilter === 'tiles');
  const categoryId = [];
  let categoriesArray = params.filter((ele) => ele.typeOfFilter === 'CHECKBOX');

  for (const ele of categoriesArray) {
    ele.checked && categoryId.push(ele.id);
  }
  let bodyVar = {
    categoryId,
  };
  let sortBy = '';
  let sortByName = 'Recent Activity';
  let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');
  radioFilterArray.forEach((ele) => {
    switch (ele?.name) {
      case 'Recent Activity':
        (sortBy = 'Recent_Activity'), (sortByName = 'Recent Activity');
        break;
      case 'A - Z':
        (sortBy = 'A_Z'), (sortByName = 'A - Z');
        break;
      case 'Z - A':
        (sortBy = 'Z_A'), (sortByName = 'Z - A');
        break;
      case 'Newest To Oldest':
        (sortBy = 'Newest_To_Oldest'), (sortByName = 'Newest To Oldest');
        break;
      case 'Oldest To Newest':
        (sortBy = 'Oldest_To_Newest'), (sortByName = 'Oldest To Newest');
        break;
      case 'Sort by Code (Ascending)':
        (sortBy = 'content_code_asc'), (sortByName = 'Sort by Code (Ascending)');
        break;
      case 'Sort by Code (Descending)':
        (sortBy = 'content_code_desc'), (sortByName = 'Sort by Code (Descending)');
        break;
      default:
        sortBy = 'Recent_Activity';
        break;
    }
  });
  let categoriesData = filterData.filtersList[1].filterTypeData.sectionData;
  let sortByData = filterData.filtersList[0].filterTypeData.sectionData;
  sortByData.forEach((data) => {
    if (data?.name == sortByName) {
      data.checked = true;
    } else {
      data.checked = false;
    }
  });
  const _filterData = {
    tiles: tilesFilterArray,
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: sortByData,
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Categories',
          sectionData: categoriesData,
        },
      },
    ],
  };
  setFilterData(_filterData);
  setFilterDataTemp(new Object(_filterData));
  await fetchCoursesListWithParams({
    bodyVar,
    sortBy,
    tilesFilterArray,
    makeRequest,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    filterData,
  });
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
};

export const _initialFilterData = {
  tiles: [
    {
      index: 0,
      id: 'DRAFT',
      title: 'Draft',
      className: 'draft',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
    {
      index: 1,
      id: 'LIVE',
      title: 'Live',
      className: 'goLive',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
    {
      index: 2,
      id: 'OFFLINE',
      title: 'Offline',
      className: 'goOffline',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
    {
      index: 3,
      id: 'ARCHIVE',
      title: 'Archive',
      className: 'archive',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
  ],
  filtersList: [
    {
      filterType: 'RADIO',
      filterTypeData: {
        sectionTitle: 'Sort By',
        sectionData: [
          {
            index: 0,
            name: 'Recent Activity',
            id: 1,
            checked: true,
            filterParameter: 'name',
            filterParameterType: 'default',
            typeOfFilter: 'RADIO',
          },
          {
            index: 1,
            name: 'A - Z',
            id: 2,
            checked: false,
            filterParameter: 'title',
            filterParameterType: 'ASC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 2,
            name: 'Z - A',
            id: 3,
            checked: false,
            filterParameter: 'title',
            filterParameterType: 'DESC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 3,
            name: 'Newest To Oldest',
            id: 4,
            checked: false,
            filterParameter: 'launch_date',
            filterParameterType: 'TIME-ASC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 4,
            name: 'Oldest To Newest',
            id: 5,
            checked: false,
            filterParameter: 'launch_date',
            filterParameterType: 'TIME-DESC',
            typeOfFilter: 'RADIO',
          },
        ],
      },
    },
    {
      filterType: 'CHECKBOX',
      filterTypeData: {
        sectionTitle: 'Categories',
        sectionData: [],
      },
    },
  ],
};

export const DrawerLP = (props) => {
  const {
    clearGlobalTranslation,
    isDrawer,
    closeDrawerModal,
    setDrawer,
    setSelectedLearningPath,
    learningPathDetailsDrawerResponse,
    setNotificationData,
    OnClickOfArchiveLP,
    dateIsoToTime,
    dateIsoToDate,
    makeRequest,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyState,
    isOnline,
    setErrorStateTemplate,
    fetchLearningPathDetails,
    setLearningPathDetailsDrawerResponse,
    handleDeleteModal,
    setDelete,
    isDelete,
    changeCourseStatus,
    setCoursesDrawerList,
    isCoursesDrawerList,
    getCourseButtonStatus,
    styles,
    DrawerFailScreen,
    isDrawerAPIFailed,
    setDrawerAPIFailed,
    setDrawerIdForBrokenLink,
    drawerIdForBrokenLink,
    setNotificationListApi,
    setDashboardNotificationCountApi,
    currentUserId,
    drawerHeight,
    setDrawerHeight,
    setMainImageBackup,
    setNewThumbnailId,
    setAllLearningPathList
  } = props;
  const router = useHistory();
  const { query = {} } = router.location;
  const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));
  const onClickHandle = (id, state) => {
    setMainImageBackup(null);
    setNewThumbnailId(null);
    let subHeader =
      state == 'Draft'
        ? 'learning_path_description'
        : state == 'Live' || state == 'Offline'
          ? 'review_and_publish'
          : '';
    router.push({
      pathname: '/newLearningPath',
      query: { id: null },
      search: `?id=${id}&sub_header=${subHeader}`,
    });
  };

  useEffect(() => {
    if (drawerHeight >= 600) {
      closeDrawerModal({
        setDrawer,
        setSelectedLearningPath,
        isDrawer,
      });
    }
  }, [drawerHeight]);
  return (
    <React.Fragment>
      {isDrawer ? (
        <div id={'divParentOfDrawer'} className={[styles.drawer_container, ''].join(' ')}>
          {/* <DrawerComponent /> */}
          <div
            className={styles.custom_overlay}
            style={drawerOverlayCalculatedHeight({ drawerHeight: drawerHeight })}
          ></div>
          <div
            className={styles.drawer_wrapper}
            style={drawerCalculatedHeight({ drawerHeight: drawerHeight })}
          >
            <div
              className={styles.mob_bar}
              draggable
              onTouchMove={(e) => {
                pullbox({ e: e, setDrawerHeight: setDrawerHeight });
              }}
              onTouchEnd={() => {
                mobDrawerToOrignalState({ setDrawerHeight: setDrawerHeight });
              }}
              onClick={() =>
                closeDrawerModal({
                  setDrawer,
                  setSelectedLearningPath,
                  isDrawer,
                })
              }
            >
              <div className={styles.line_bar}></div>
            </div>

            {!isDrawerAPIFailed ? (
              <div className={styles.middle_container}>
                <div className={styles.header}>
                  <div className={styles.title}>Learning Path Details</div>
                  <div
                    className={styles.close_wrapper}
                    onClick={() =>
                      closeDrawerModal({
                        setDrawer,
                        setSelectedLearningPath,
                        isDrawer,
                      })
                    }
                  >
                    <div className={styles.close_icon} />
                  </div>
                </div>
                <div
                  className={
                    learningPathDetailsDrawerResponse?.state === 'Archive'
                      ? [
                        styles.edit_course_btn_wrapper,
                        styles.edit_course_btn_wrapper_disabled,
                      ].join(' ')
                      : styles.edit_course_btn_wrapper
                  }
                  onClick={() => {
                    clearGlobalTranslation();
                    if (learningPathDetailsDrawerResponse?.state === 'Archive') {
                      OnClickOfArchiveLP({
                        setNotificationData,
                        learningPathDetailsDrawerResponse,
                      });
                    }
                    // if (
                    //   learningPathDetailsDrawerResponse?.state?.toString()?.toLowerCase() ===
                    //   'draft'
                    // )
                    else {
                      onClickHandle(
                        learningPathDetailsDrawerResponse?.learningPathId,
                        learningPathDetailsDrawerResponse?.state,
                      );
                    }
                  }}
                >
                  <div className={styles.edit_icon}></div>
                  <div className={styles.txt}>
                    {learningPathDetailsDrawerResponse?.state?.toString()?.toLowerCase() === 'draft'
                      ? 'Go to Learning Path Wizard'
                      : 'Edit Learning Path'}
                  </div>
                </div>
                <div className={styles.img_wrapper}>
                  {learningPathDetailsDrawerResponse?.bannerImage ? (
                    <img src={learningPathDetailsDrawerResponse?.bannerImage} alt="16:9 selected" />
                  ) : (
                    <div className={styles.selected_img}></div>
                  )}
                </div>
                <div className={[styles.info_container, ' '].join(' ')}>
                  <div className={styles.course_title}>
                    {learningPathDetailsDrawerResponse?.primaryTitle || 'NA'}
                  </div>
                  <div className={styles.description_wrapper}>
                    <div className={styles.label}>Description</div>
                    <div className={styles.value}>
                      {learningPathDetailsDrawerResponse?.primaryDescription || 'NA'}
                    </div>
                  </div>
                </div>
                <div className={styles.upload_details_wrapper}>
                  <div className={styles.upload_info}>
                    <div className={styles.title}>Created By</div>
                    <div className={styles.info_text}>
                      {learningPathDetailsDrawerResponse?.createdBy || 'NA'}
                    </div>
                  </div>
                  <div className={styles.upload_info}>
                    <div className={styles.title}>Launch Date</div>
                    <div className={styles.info_text}>
                      {learningPathDetailsDrawerResponse?.launchDate?.length > 0 ? (
                        <div>
                          <div>{dateIsoToTime(learningPathDetailsDrawerResponse?.launchDate)}</div>
                          <div>{dateIsoToDate(learningPathDetailsDrawerResponse?.launchDate)}</div>
                        </div>
                      ) : (
                        <div>NA</div>
                      )}
                    </div>
                  </div>
                  <div className={styles.upload_info}>
                    <div className={styles.title}>Last Updated</div>
                    <div className={styles.info_text}>
                      <div>
                        <div>{learningPathDetailsDrawerResponse?.updatedBy}</div>
                        {learningPathDetailsDrawerResponse?.updatedDate?.length > 0 ? (
                          <div>
                            <div>
                              {dateIsoToTime(learningPathDetailsDrawerResponse?.updatedDate)}
                            </div>
                            <div>
                              {dateIsoToDate(learningPathDetailsDrawerResponse?.updatedDate)}
                            </div>
                          </div>
                        ) : (
                          <div>NA</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Please try to reuse the commented code  */}
                <div
                  className={[
                    styles.course_status,
                    styles[learningPathDetailsDrawerResponse?.state?.toString().toLowerCase()],
                  ].join(' ')}
                >
                  <div className={styles.txt_wrapper}>
                    <div className={styles.txt}>This Learning Path is </div>
                    <div className={styles.status_txt}>
                      {learningPathDetailsDrawerResponse?.state}
                    </div>
                  </div>
                  <div
                    className={[
                      styles.status_btn,
                      learningPathDetailsDrawerResponse?.state == 'Draft'
                        ? styles.delete_draft_btn
                        : '',
                    ].join(' ')}
                    onClick={() => {
                      if (
                        learningPathDetailsDrawerResponse?.state?.toString()?.toLowerCase() ===
                        'draft'
                      ) {
                        handleDeleteModal({ setDelete, isDelete });
                      } else if (
                        learningPathDetailsDrawerResponse?.state?.toString()?.toLowerCase() ===
                        'live'
                      ) {
                        changeCourseStatus({
                          status: 'offline',
                          makeRequest,
                          learningPathDetailsDrawerResponse,
                          setLearningPathAPIResponse,
                          setLearningPathAPIResponseCopy,
                          setSearchFilterEmptyState,
                          isOnline,
                          setErrorStateTemplate,
                          fetchLearningPathDetails,
                          setLearningPathDetailsDrawerResponse,
                          setDrawerAPIFailed,
                          setDrawerIdForBrokenLink,
                          drawerIdForBrokenLink,
                          setNotificationListApi,
                          setDashboardNotificationCountApi,
                          currentUserId,
                          setNotificationData,
                          oktaToken,
                          setAllLearningPathList
                        });
                      } else if (
                        learningPathDetailsDrawerResponse?.state?.toString()?.toLowerCase() ===
                        'offline'
                      ) {
                        changeCourseStatus({
                          status: 'live',
                          makeRequest,
                          learningPathDetailsDrawerResponse,
                          setLearningPathAPIResponse,
                          setLearningPathAPIResponseCopy,
                          setSearchFilterEmptyState,
                          isOnline,
                          setErrorStateTemplate,
                          fetchLearningPathDetails,
                          setLearningPathDetailsDrawerResponse,
                          setDrawerAPIFailed,
                          setDrawerIdForBrokenLink,
                          drawerIdForBrokenLink,
                          setNotificationListApi,
                          setDashboardNotificationCountApi,
                          currentUserId,
                          setNotificationData,
                          oktaToken,
                          setAllLearningPathList
                        });
                      }
                    }}
                  >
                    <div className={styles.text}>
                      {getCourseButtonStatus({ learningPathDetailsDrawerResponse })}
                    </div>
                  </div>
                </div>
                {learningPathDetailsDrawerResponse?.courses?.length > 0 && (
                  <div className={[styles.img_instances_container, ''].join(' ')}>
                    <div className={styles.dropdown_container}>
                      <div className={styles.top_bar}>
                        <div className={styles.title}>Courses in this Learning Path </div>
                        <div
                          className={[
                            styles.toggle_arrow,
                            isCoursesDrawerList ? styles.up_arrow : styles.down_arrow,
                          ].join(' ')}
                          onClick={() => {
                            setCoursesDrawerList(!isCoursesDrawerList);
                          }}
                        ></div>
                      </div>
                      {isCoursesDrawerList && (
                        <div className={styles.list_wrapper}>
                          {learningPathDetailsDrawerResponse?.courses?.length > 0 &&
                            learningPathDetailsDrawerResponse?.courses?.map((item, index) => {
                              return (
                                <div className={styles.list} key={index}>
                                  <div className={styles.item_wrapper}>
                                    <div className={styles.item}>
                                      {index + 1 + '. ' + item.title}
                                    </div>
                                  </div>
                                  <StatusLabel item={item} large={true} />
                                  {/* <div
                                    className={[
                                      styles.instance_status,
                                      item?.state == 'Live'
                                        ? styles.live
                                        : item?.state == 'Draft'
                                        ? styles.draft
                                        : item.state == 'Archive'
                                        ? styles.archive
                                        : item.state == 'Offline'
                                        ? styles.offline
                                        : '',
                                    ].join(' ')} 
                                  >
                                    {item?.state}
                                  </div> */}
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.middle_container}>
                <div className={styles.header}>
                  <div className={styles.title}>Learning Path Details</div>
                  <div
                    className={styles.close_wrapper}
                    onClick={() =>
                      closeDrawerModal({
                        setDrawer,
                        setSelectedLearningPath,
                        isDrawer,
                      })
                    }
                  >
                    <div className={styles.close_icon} />
                  </div>
                </div>
                <DrawerFailScreen
                  drawerId={drawerIdForBrokenLink}
                  drawerTryAgainCall={(item) => {
                    fetchLearningPathDetails({
                      item,
                      makeRequest,
                      setLearningPathDetailsDrawerResponse,
                      setDrawerAPIFailed,
                      setDrawerIdForBrokenLink,
                      drawerIdForBrokenLink,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export const CardViewLP = (props) => {
  const {
    isCardView,
    learningPathAPIResponse,
    styles,
    isDrawer,
    learningPathDetailsDrawerResponse,
    setDrawer,
    setSelectedLearningPath,
    makeRequest,
    setLearningPathDetailsDrawerResponse,
    setCoursesDrawerList,
    selectedLearningPath,
    dateIsoToDate,
    isListView,
    EmptyScreenComponent,
    isSearchFilterEmptyState,
    setDrawerAPIFailed,
    setDrawerIdForBrokenLink,
    drawerIdForBrokenLink,
    isLoading,
  } = props;
  return (
    <React.Fragment>
      {isCardView && learningPathAPIResponse?.length > 0 && !isLoading ? (
        <div
          className={[
            styles.card_wrapper,
            //isDrawer ? styles.card_wrapper_with_drawer : '',
            'bootstrap-wrapper',
          ].join(' ')}
        >
          {/* styles.card_wrapper_with_drawer */}
          <div className={styles.row_wrapper}>
            {/* <div
                className={[styles.card_holder, ""].join(" ")}
              > */}

            {isCardView &&
              learningPathAPIResponse?.map((learningPath, index) => {
                return (
                  <div
                    key={index}
                    id={learningPath?.learningPathId}
                    className={[styles.card_holder].join(' ')}
                    onClick={(e) => {
                      selectedAudiGrp({
                        e,
                        item: learningPath?.learningPathId,
                        isDrawer,
                        learningPathDetailsDrawerResponse,
                        setDrawer,
                        setSelectedLearningPath,
                        makeRequest,
                        setLearningPathDetailsDrawerResponse,
                        setDrawerAPIFailed,
                        setDrawerIdForBrokenLink,
                        drawerIdForBrokenLink,
                        fetchLearningPathDetails,
                      });
                      setCoursesDrawerList(true);
                    }}
                  >
                    <div
                      className={[
                        styles.card,
                        selectedLearningPath === learningPath?.learningPathId
                          ? styles.selected
                          : '',
                      ].join(' ')}
                    >
                      <div className={styles.view_details}>
                        <div className={styles.vd_icon}></div>
                      </div>
                      <div className={styles.image_wrapper}>
                        <div className={[styles.img, styles.learning_path].join(' ')}>
                          {learningPath?.thumbnail ? (
                            <img
                              className={styles.img}
                              src={learningPath?.thumbnail}
                              alt="selected course img"
                            />
                          ) : (
                            <div className={[styles.empty_state_img].join(' ')}></div>
                          )}
                        </div>
                      </div>
                      <div className={styles.left_wrapper}>
                        <div className={styles.left_inner_wrapper}>
                          {/* <StatusLabel item={learningPath} large={false} /> */}
                          <div
                            className={[
                              styles.instance_status,
                              // styles.draft_sts,
                              styles[learningPath?.state?.toString().toLowerCase()],
                            ].join(' ')}
                          >
                            {learningPath?.state}
                          </div>
                          <div className={styles.date_wrapper}>
                            <div
                              className={
                                learningPath?.state === 'Live'
                                  ? styles.calendar_icon
                                  : learningPath?.state === 'Offline'
                                    ? styles.offline_cal_icon
                                    : learningPath?.state === 'Archive'
                                      ? styles.archive_cal_icon
                                      : null
                              }
                            ></div>
                            <div className={styles.date}>
                              {learningPath?.state !== 'Draft' && (
                                <div className={[styles.txt].join(' ')}>
                                  <div className={styles.date_txt}>
                                    {learningPath?.launchDate
                                      ? dateIsoToDate(learningPath?.launchDate)
                                      : 'NA'}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={styles.description}>{learningPath?.title}</div>
                        <div className={styles.course_wrapper}>
                          <div className={styles.course_icon} />
                          <div className={styles.course_number}>{learningPath?.courseCount}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : isSearchFilterEmptyState && !isListView && !isLoading ? (
        <EmptyScreenComponent />
      ) : isCardView && isLoading ? (
        <div
          className={[
            styles.card_wrapper,
            //isDrawer ? styles.card_wrapper_with_drawer : '',
            'bootstrap-wrapper',
          ].join(' ')}
        >
          <div className={styles.row_wrapper}>
            <LoadingShimmer count={20} isLearningPath={true} isLpLandingPage={true} />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export const ListViewLP = (props) => {
  const {
    isCardView,
    learningPathAPIResponse,
    styles,
    isDrawer,
    learningPathDetailsDrawerResponse,
    setDrawer,
    setSelectedLearningPath,
    makeRequest,
    setLearningPathDetailsDrawerResponse,
    setCoursesDrawerList,
    selectedLearningPath,
    dateIsoToDate,
    isListView,
    EmptyScreenComponent,
    isSearchFilterEmptyState,
    dateIsoToTime,
    setDrawerAPIFailed,
    setDrawerIdForBrokenLink,
    drawerIdForBrokenLink,
    fetchLearningPathDetails,
    isLoading,
    onSort = () => { },
  } = props;

  const [sortProps, setSortProps] = useState({
    field: null,
    dir: 'none'
  });

  const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: 'none'
  };

  const sortData = (data, field, direction) => {
    if (!data || !field || direction === SORT_DIRECTIONS.NONE) return data;

    return [...data].sort((a, b) => {
      let aValue = a[field];
      let bValue = b[field];


      if (!aValue) return 1;
      if (!bValue) return -1;

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }


      if (typeof aValue === 'number') {
        if (aValue < bValue) return direction === SORT_DIRECTIONS.ASC ? -1 : 1;
        if (aValue > bValue) return direction === SORT_DIRECTIONS.ASC ? 1 : -1;
        return 0;
      }

      if (aValue < bValue) return direction === SORT_DIRECTIONS.ASC ? -1 : 1;
      if (aValue > bValue) return direction === SORT_DIRECTIONS.ASC ? 1 : -1;
      return 0;
    });
  };

  // Sorting logic from RichGrid
  const getChangedSortPropsOnClick = (changedSortField) => {
    if (sortProps.field === changedSortField) {
      if (sortProps.dir === SORT_DIRECTIONS.ASC) {
        return { field: changedSortField, dir: SORT_DIRECTIONS.DESC };
      } else {
        return { field: changedSortField, dir: SORT_DIRECTIONS.ASC };
      }
    } else {
      return { field: changedSortField, dir: SORT_DIRECTIONS.ASC };
    }
  };

  // Handler for sort clicks
  const handleSort = (field) => {
    const newSortProps = getChangedSortPropsOnClick(field);
    setSortProps(newSortProps);
    onSort(newSortProps);
  };

  return (
    <React.Fragment>
      {isListView && learningPathAPIResponse?.length > 0 && !isLoading ? (
        <div className={[styles.table_main_wrapper, ''].join(' ')}>
          <div className={styles.table_container}>
            <div className={styles.tbl_header}>
              <div className={styles.tbl_head}>Thumbnail</div>
              <div className={styles.tbl_head_wrapper}>
                <div
                  className={`${styles.sort_container} ${styles.title_div}`}
                  onClick={() => handleSort('title')}
                >
                  <div className={`${styles.tbl_head} ${styles.sort_text}`}>Title</div>
                  <div className={styles.sort_icon}>
                    <img
                      src={table_sorting_icon}
                      alt="sort-icon"
                    />
                  </div>
                </div>
                <div
                  className={`${styles.sort_container} ${styles.lp_text}`}
                  onClick={() => handleSort('contentCode')}
                >
                  <div className={`${styles.tbl_head} ${styles.sort_text}`}>LP Code</div>
                  <div className={styles.sort_icon}>
                    <img
                      src={table_sorting_icon}
                      alt="sort-icon"
                    />
                  </div>
                </div>
                <div
                  className={`${styles.sort_container} ${styles.ld_text}`}
                  onClick={() => handleSort('launchDate')}
                >
                  <div className={`${styles.tbl_head} ${styles.sort_text}`}>Launch date</div>
                  <div className={styles.sort_icon}>
                    <img
                      src={table_sorting_icon}
                      alt="sort-icon"
                    />
                  </div>
                </div>
                <div
                  className={`${styles.sort_container} ${styles.course_text}`}
                  onClick={() => handleSort('courseCount')}
                >
                  <div className={styles.book_white_icon}></div>
                  <div className={`${styles.tbl_head} ${styles.sort_text}`}>Courses</div>
                  <div className={styles.sort_icon}>
                    <img
                      src={table_sorting_icon}
                      alt="sort-icon"
                    />
                  </div>
                </div>
                <div
                  className={styles.sort_container}
                  onClick={() => handleSort('state')}
                >
                  <div className={`${styles.tbl_head} ${styles.sort_text}`}>Status</div>
                  <div className={styles.sort_icon}>
                    <img
                      src={table_sorting_icon}
                      alt="sort-icon"
                    />
                  </div>
                </div>
              </div>

            </div>
            <div className={styles.tbl_body}>
              <div className={[styles.tbl_row_wrapper].join(' ')}>
                {isListView &&
                  sortData(learningPathAPIResponse, sortProps.field, sortProps.dir)?.map((learningPath, index) => {
                    return (
                      <div
                        key={index}
                        id={learningPath?.learningPathId}
                        className={[
                          styles.tbl_row,
                          selectedLearningPath === learningPath?.learningPathId
                            ? styles.selected
                            : '',
                        ].join(' ')}
                        onClick={(e) => {
                          selectedAudiGrp({
                            e,
                            item: learningPath?.learningPathId,
                            isDrawer,
                            learningPathDetailsDrawerResponse,
                            setDrawer,
                            setSelectedLearningPath,
                            makeRequest,
                            setLearningPathDetailsDrawerResponse,
                            setDrawerAPIFailed,
                            setDrawerIdForBrokenLink,
                            drawerIdForBrokenLink,
                            fetchLearningPathDetails,
                          });
                          setCoursesDrawerList(true);
                        }}
                      >
                        <div className={styles.tbl_data}>
                          <div className={styles.inner_data}>
                            {learningPath?.bannerImage ? (
                              <img
                                className={styles.img}
                                src={learningPath?.bannerImage}
                                alt="selected course img"
                              />
                            ) : (
                              <div className={[styles.empty_state_img].join(' ')}></div>
                            )}
                          </div>
                        </div>
                        <div className={styles.tbl_data_wrapper}>
                          <div className={styles.tbl_data}>
                            <div className={styles.inner_data}>
                              <div className={[styles.txt, ' '].join(' ')}>
                                {learningPath?.title || 'NA'}
                              </div>
                            </div>
                          </div>
                          <div className={styles.tbl_data}>
                            <div className={styles.inner_data}>
                              <div className={[styles.txt, ' '].join(' ')}>
                                {learningPath?.contentCode || 'NA'}
                              </div>
                            </div>
                          </div>

                          <div className={styles.tbl_data}>
                            <div className={styles.inner_data}>
                              {learningPath?.state === 'Draft' && (
                                <div className={[styles.txt].join(' ')}>
                                  <div>
                                    {learningPath?.launchDate
                                      ? dateIsoToTime(learningPath?.launchDate)
                                      : 'NA'}
                                  </div>
                                </div>
                              )}
                              {learningPath?.state !== 'Draft' && (
                                <div className={[styles.txt].join(' ')}>
                                  <div>
                                    {learningPath?.launchDate
                                      ? dateIsoToTime(learningPath?.launchDate)
                                      : 'NA'}
                                  </div>
                                  <div>
                                    {learningPath?.launchDate
                                      ? dateIsoToDate(learningPath?.launchDate)
                                      : 'NA'}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={styles.tbl_data}>
                            <div className={styles.inner_data}>
                              <div className={styles.course_num}>{learningPath?.courseCount}</div>
                            </div>
                          </div>

                          <div className={styles.tbl_data}>
                            <div className={styles.inner_data}>
                              <div
                                className={`${styles.status}
                                   ${styles[learningPath?.state?.toLowerCase()]}`}
                              >
                                <div className={styles.status_txt}>
                                  {learningPath?.state || 'NA'}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : isSearchFilterEmptyState && !isCardView && !isLoading ? (
        <EmptyScreenComponent />
      ) : isListView && isLoading ? (
        <div className={[styles.table_main_wrapper, ''].join(' ')}>
          <div className={styles.table_container}>
            <div className={styles.tbl_header}>
              <div className={styles.tbl_head}>Thumbnail</div>
              <div className={styles.tbl_head_wrapper}>
                <div className={styles.tbl_head}>Title</div>
                <div className={styles.tbl_head}>LP Code</div>
                <div className={styles.tbl_head}>Launch date</div>
                <div className={styles.tbl_head}>
                  <div className={styles.book_white_icon}></div>
                  <div className={styles.txt_hd}>Courses</div>
                </div>
                <div className={styles.tbl_head}>Status</div>
              </div>
            </div>
            <div className={styles.tbl_body}>
              <div className={[styles.tbl_row_wrapper].join(' ')}>
                <LoadingShimmerList count={20} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};
export const LPdataArray = (
  data,
  addedCourseList,
  pillsDataKeyword,
  selectedSkills,
  categoriesListFull,
) => {
  let LPDetails = {};
  let LPDetailsCoursesList = [];
  let tempCategories = [];
  let tempSkills = [];
  let tempSkillsNewAdd = [];
  let tempKeywords = [];
  let tempKeywordsNew = [];
  addedCourseList.forEach((ele) => {
    LPDetailsCoursesList.push(ele.courseId);
  });
  LPDetails = data;
  LPDetails.courses = LPDetailsCoursesList ? LPDetailsCoursesList : [];
  if (data?.audienceGroups?.addAudGrpIds?.length > 0) {
    let addAudGrpIdsList = [];
    data?.audienceGroups?.addAudGrpIds.forEach((ele) => {
      addAudGrpIdsList.push({
        audGrpId: ele.audGrpId,
        learningPathType: ele.courseType,
      });
    });
    LPDetails.audienceGroups = {
      deleteAudGrpIds: [],
      addAudGrpIds: addAudGrpIdsList || [],
    };
  } else {
    delete LPDetails.audienceGroups;
  }
  delete LPDetails?.launchDate;
  delete LPDetails?.category;
  delete LPDetails?.skills;
  delete LPDetails?.keywords;
  delete LPDetails?.createdBy;
  // data?.keywords?.map((ele) => {
  //   ele.id ? tempKeywords.push(ele.id) : tempKeywords.push(ele);
  // });
  if (pillsDataKeyword) {
    pillsDataKeyword.forEach((ele) => {
      tempKeywords.push(ele.id);
    });
  }
  if (selectedSkills) {
    selectedSkills.forEach((ele) => {
      tempSkills.push(ele.id);
    });
  }
  if (categoriesListFull) {
    categoriesListFull.forEach((ele) => {
      tempCategories.push(ele.id);
    });
  }
  tempKeywordsNew = [...new Set(tempKeywords)];
  tempSkillsNewAdd = [...new Set(tempSkills)];
  LPDetails.categories = tempCategories;
  LPDetails.skills = tempSkillsNewAdd;
  LPDetails.keywords = tempKeywordsNew;
  return LPDetails;
};
