import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteLearningRecords,
  fetchAboList,
  setAboListPaginationProps,
  setAboListResetPaginationProps,
  setAboListSortProps,
  setCoursePassedDropdown,
  setCourseStatusDropdown,
  setEditFormCourseScore,
  setEditFormStartDate,
  setEditFormStatusDate,
  setIsRoasterFilterFormDirty,
  setNavigateTo,
  setSelectedAboList,
  setSelectedAffiliateCode,
} from '../../../redux/actions/roaster/roasterListingActions';
import styles from './AboListing.module.scss';
import Header from '../../shared/header';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import RichGrid from '../../shared/richgrid';
import {ABO_LIST} from './data';
import Badge from '../../badge';
import RoasterNewComponent from '../../shared/RoasterNewComponent';
import DeleteConfirmationModal from '../deleteRoasterConfirmation';
import ModalWrapper from '../../shared/Modal/ModalWrapper/ModalWrapper';
import Background from '../../shared/Modal/Background/Background';
import RoasterFilterModal, {DropDown} from '../../shared/RoasterFilterModal';
import FilterComponent from '../../shared/filter';
import {Hint} from 'react-autocomplete-hint';
import DateRangerPicker from '../../shared/dateRangerPicker';
import Notification from '../../shared/notification';
import CheckBoxBlue from '../../shared/checkboxBlue';
import LearningRecordCard from '../learningRecordCard';
import AdditionIcon from '../../../styles/images/addition_icon_blue.svg';
import { setNotificationData } from '../../../redux/actions/uiActions';
import { convertFullDateToUTC, formatDateInUserTimezone } from '../../../utils/date';
import AlertModal from '../../shared/alertModal/AlertModal';
import table_sorting_icon from '../../../styles/images/table_sorting_icon.svg';
import dayjs from 'dayjs';
import { SORT_DIRECTIONS } from '../../shared/richgrid/constants';

const _initialFilterData = {
  filtersList: [
    {
      filterType: 'RADIO',
      filterTypeData: {
        sectionTitle: 'Filter',
        sectionData: [
          {
            index: 0,
            name: 'All',
            id: 1,
            checked: true,
            filterParameter: '',
            filterParameterType: 'default',
            typeOfFilter: 'RADIO',
          },
          {
            index: 1,
            name: 'In Progress',
            id: 2,
            checked: false,
            filterParameter: 'In-Progress',
            filterParameterType: 'ASC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 2,
            name: 'Completed',
            id: 3,
            checked: false,
            filterParameter: 'Completed',
            filterParameterType: 'DESC',
            typeOfFilter: 'RADIO',
          },
        ],
      },
    },
  ],
};

const ActionButtonIcon = (props) => {
  const { flexProperty = 0, marginLeft = 0, actionBtnType = '', handleOpenModal = () => { } } = props;
  const backGroundUrl = {
    flexProperty: flexProperty,
    backgroundImage: `url(${actionBtnType})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '18px',
    height: '18px',
  };
  return (
    <>
      <div className={styles.actionBtnContainer} onClick={handleOpenModal}>
        <div className={styles.actionBtn} style={backGroundUrl}></div>
      </div>
    </>
  );
};

const AboListing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const router = useHistory();
  const selectedCourse = useSelector((state) => state?.courseManagement?.selectedCourse);
  const affCodeList = useSelector((state) => state?.roasterListing?.affiliateCodeList);

  const [search, setSearch] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [predictedSearch, setPredictedSearch] = useState([]);
  const [onSearchFocus, setonSearchFocus] = useState(false);
  const [filterData, setFilterData] = useState(_initialFilterData);
  const [isAlertModalopen, setIsAlertModalOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState({
    index: 0,
    name: 'All',
    id: 1,
    checked: true,
    filterParameter: '',
    filterParameterType: 'default',
    typeOfFilter: 'RADIO',
  });

  const currentDate = new Date();
  const from = new Date();
  from.setDate(currentDate.getDate() - 2); // Set 'from' to two days ago
  const to = new Date(); // 'to' is the current date

  const [selectedFilterDate, setSelectedFilterDate] = useState({
    from: from,
    to,
  });

  const { notificationData } = useSelector(state => state?.uiState);
  const DATE_FORMAT = useSelector(state => state?.uiState?.remoteConfig?.date_format || 'MM/DD/YYYY');

  const {
    aboListLoader,
    aboList,
    selectedAboList,
     aboListSortProps,
    aboListPaginationProps,
    selectedAffCode,
    isRoasterFilterFormDirty,
    navigateTo,
  } = useSelector((state) => state?.roasterListing);

  const [addNewRoaster, setAddNewRoaster] = useState(false);
  const [input, setInput] = useState('');
  const [isDeleteRecord, setDeleteRecord] = useState(false);

  const handlePopState = () => {
    dispatch(setIsRoasterFilterFormDirty(false))
  }

  useState(()=>{
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  },[])


  const handleCloseAddRecordModal = (callApi) => {
    if(callApi){
      dispatch(fetchAboList({ payload: getFetchAboListPayload(), paginationKeys: aboListPaginationProps?.keys }));
    }
    setAddNewRoaster(false);
  };

  const handleOpenAddRecordModal = () => {
    setAddNewRoaster(true);
  };

  const handleOpenDeleteRecordModal = () => {
    if(selectedAboList?.length){
      setDeleteRecord(true);
      return
    }
    dispatch(setNotificationData({
      show: true,
      type: 'FAILURE',
      title: 'Disabled',
      description: 'Please select at least one checkbox from the table',
    }));
  };

  const getFetchAboListPayload = () => {
    const { exclusiveStartKey, createdAt } = aboListPaginationProps?.keys[aboListPaginationProps?.currentPageNo - 1] || {};

    const payload = {
      courseId: selectedCourse?.courseId,
      pageSize: aboListPaginationProps?.pageSize,
      salesPlanAff: selectedAffCode?.salesPlanAffCode,
      aboNumber: search,
      startDate: dayjs(selectedFilterDate?.from).endOf('day').toISOString() || undefined,
      endDate: dayjs(selectedFilterDate?.to).endOf('day').toISOString() || undefined,
      status: selectedFilter?.filterParameter,
      exclusiveStartKey: exclusiveStartKey || '',
      createdAt: createdAt || ''
    };

    return payload;
  };

  /**
   * Reset the values of the filters
   */

  useEffect(()=>{
    if(selectedAboList?.length === 0){
      dispatch(setEditFormCourseScore({value: null}))
      dispatch(setCourseStatusDropdown({
        value: {id: '', name: ''},
        Placeholder: 'Status',
        options: [
          {id: 'Completed', name: 'Completed'},
          {id: 'In-Progress', name: 'In Progress'},
        ],
        isDisabled: true,
        disabledReason: "Please select at least one checkbox from the table"
      })); 
      dispatch( setEditFormStartDate({
        formattedDate: '',
        hour: '',
        minute: '',
        period: '',
        isDisabled: true,
        disabledReason: "Please select course status first"
      }))
      dispatch(setEditFormStatusDate( {
        formattedDate: '',
        hour: '',
        minute: '',
        period: '',
        isDisabled: true,
        disabledReason: "Please select course status first"
      }))
      dispatch(setCoursePassedDropdown({value: {id: '', name: ''}}))
    }
  },[selectedAboList])

  useEffect(() => {
    if(search === '') {
      dispatch(setAboListResetPaginationProps());
      dispatch(fetchAboList({ payload: getFetchAboListPayload() }))
    }
  }, [search])

  useEffect(()=>{
    if(!aboList || aboList?.length===0){
      dispatch(setSelectedAboList([]))
    }
  },[aboList])

  useEffect(() => {
    dispatch(setAboListResetPaginationProps());
    dispatch(setSelectedAboList([]));
    
    window.addEventListener('beforeunload', () => {
      dispatch(setAboListResetPaginationProps());
      dispatch(setSelectedAboList([]));
    });

    return () => {
      window.removeEventListener('beforeunload', () => {
        dispatch(setAboListResetPaginationProps());
        dispatch(setSelectedAboList([]));
      });
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAboList({ payload: getFetchAboListPayload(), paginationKeys: aboListPaginationProps?.keys }));
  }, [selectedAffCode, selectedFilter, aboListPaginationProps?.currentPageNo]);

  const ABO_LISTING_COLUMNS = [
    {
      field: 'name',
      header: <label className={styles.aboListingTableHeaders} style={{ width:'170px', marginRight:'5px'}}>ABO Name <span className={styles.sortIcon} onClick={() =>
        dispatch(setAboListSortProps(getChangedSortPropsOnClick('name')))
      }><img src={table_sorting_icon} alt="sort-icon" /></span></label>,
      renderLogic: (row, idx) => <div style={{width: '170px', marginRight:'5px'}}>{row?.name || '---'}</div>,
    },
    {
      field: 'aboNumber',
      header: <label className={styles.aboListingTableHeaders} style={{ width:'12%'}}>ABO Number <span className={styles.sortIcon} onClick={() =>
        dispatch(setAboListSortProps(getChangedSortPropsOnClick('aboNumber')))
      }><img src={table_sorting_icon} alt="sort-icon" /></span></label>,
      renderLogic: (row, idx) => <div style={{width:'12%'}}>{row?.aboNumber || '---'}</div>,
    },
    {
      field: 'partyId',
      header: <label className={styles.aboListingTableHeaders} style={{ width:'11%'}}>Party ID <span className={styles.sortIcon} onClick={() =>
        dispatch(setAboListSortProps(getChangedSortPropsOnClick('partyId')))
      }><img src={table_sorting_icon} alt="sort-icon" /></span></label>,
      renderLogic: (row, idx) => <div style={{width:'11%'}}>{row?.partyId || '---'}</div>,
    },
    {
      field: 'status',
      header: <label className={styles.aboListingTableHeaders} style={{ width:'9%',minWidth:'105px'}}>Status <span className={styles.sortIcon} onClick={() =>
        dispatch(setAboListSortProps(getChangedSortPropsOnClick('status')))
      }><img src={table_sorting_icon} alt="sort-icon" /></span></label>,
      renderLogic: (row, idx) => (
        <div style={{width:'9%', minWidth:'105px'}}>
          <Badge
            text={row?.status || '---'}
            variant={row?.status === 'Completed' ? 'success' : 'warning'}
          />
        </div>
      ),
    },
    {
      field: 'startDate',
      header: <label className={styles.aboListingTableHeaders} style={{ width:'17%'}}>Start Date <span className={styles.sortIcon} onClick={() =>
        dispatch(setAboListSortProps(getChangedSortPropsOnClick('startDate')))
      }><img src={table_sorting_icon} alt="sort-icon" /></span></label>,
      renderLogic: (row, idx) => {
        const { formattedDate, timezoneAbbreviation } = formatDateInUserTimezone(row?.startDate, DATE_FORMAT);

        return <div style={{ width:'17%'}}>{row?.startDate ? `${formattedDate} ${timezoneAbbreviation}` : '---'}</div>
      }
    },
    {
      field: 'statusDate',
      header: <label className={styles.aboListingTableHeaders} style={{width:'17%'}}>Status Date <span className={styles.sortIcon} onClick={() =>
        dispatch(setAboListSortProps(getChangedSortPropsOnClick('statusDate')))
      }><img src={table_sorting_icon} alt="sort-icon" /></span></label>,
      renderLogic: (row, idx) => {
        const { formattedDate, timezoneAbbreviation } = formatDateInUserTimezone(row?.statusDate, DATE_FORMAT);

        return <div style={{width:'17%'}}>{row?.statusDate ? `${formattedDate} ${timezoneAbbreviation}` : '---'}</div>
      },
    },
    {
      header: <label className={styles.aboListingTableHeaders} style={{ width:'4%'}}>Score</label>,
      renderLogic: (row, idx) => <div style={{width:'4%'}}>{row?.score || '---'}</div>,
    },
    {
      header: <label className={styles.aboListingTableHeaders} style={{ width:'5%'}}>Passed</label>,
      renderLogic: (row, idx) => (
        <div style={{width:'5%'}}>{row?.passed === true ? 'Yes' : row?.passed === false ? 'No' : '---'}</div>
      ),
    },
  ];

  const NoRecordsFoundComponent = () => (
    <div className="text-left">
      <p className="m-0">No Learning Records found.</p>
      <p className="m-0">
        Try adjusting the dates, verifying your search terms, or confirming you have selected the
        correct course.
      </p>
    </div>
  );

  const handleDeleteInput = (props) => {
    const {value, setInput} = props;
    setInput(value);
  };

  const handleDeleteRecord = () => {
    dispatch(deleteLearningRecords({ selectedAboList, selectedCourse, selectedAffCode }, getFetchAboListPayload()));
    setDeleteRecord(false);
    dispatch(setSelectedAboList([]));
  };

  const handleSearchInput = (event) => {
    setSearch(event?.target?.value);
    setPredictedSearch([]);
  };

  const handleAddNewRoaster=()=>{
     setAddNewRoaster(true)
  }

  const getChangedSortPropsOnClick = (changedSortField) => {
    if (aboListSortProps.field == changedSortField) {
      if (aboListSortProps.dir == SORT_DIRECTIONS.ASC) {
        return { field: changedSortField, dir: SORT_DIRECTIONS.DESC };
      } else {
        return { field: changedSortField, dir: SORT_DIRECTIONS.ASC };
      }
    } else {
      return { field: changedSortField, dir: SORT_DIRECTIONS.ASC };
    }
  };

  const SearchBar = (
    <div className={[styles.search_main_wrapper, search.length > 0 ? styles.active : ''].join(' ')}>
      <div className={[styles.search_wrapper, onSearchFocus ? styles.outline : ''].join(' ')}>
        <Hint options={predictedSearch} allowTabFill={true}>
          <input
            type="text"
            placeholder="ABO Number"
            value={search}
            onChange={(e) => handleSearchInput(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if(aboListPaginationProps?.currentPageNo != 1) {
                  dispatch(setAboListResetPaginationProps());
                } else {
                  dispatch(fetchAboList({ payload: getFetchAboListPayload() }))
                }                
              }
            }}
            className={styles.search_input}
            // onKeyDown={(e) => {
            //   if (e.keyCode === 13 && search?.length < 3 && search?.length > 0) {
            //     setNotificationData({
            //       show: false,
            //       type: 'WARNING',
            //       title: 'Insufficient Search Input',
            //       description: 'To search for any Course, enter 3 or more characters.',
            //     });
            //   }
            // }}
            onFocus={() => {
              setonSearchFocus(true);
            }}
            onBlur={() => {
              search?.length == 0 && setonSearchFocus(false);
            }}
          />
        </Hint>
        {/* {search?.length === 0 && !onSearchFocus && ( */}
        <div
          className={styles.icon_wrapper}
          onClick={() => dispatch(fetchAboList({ payload: getFetchAboListPayload(), paginationKeys: aboListPaginationProps?.keys }))}
        >
          <div className={styles.search} />
        </div>
        {/* )} */}
        {/* {(search?.length > 0 || onSearchFocus) && (
            <div
              className={styles.icon_wrapper}
              onClick={() => {
                setSearch('');
                setonSearchFocus(false);
              }}
            >
              <div className={styles.cross_icon} />
            </div>
          )} */}
      </div>
    </div>
  );

  const sortAboListRecords = () => {
    if (!aboList || !aboList.length || !aboListSortProps) return [];

    const {field, dir} = aboListSortProps;

    // Sorting function to handle different data types
    const compare = (a, b) => {
      let valueA = a[field];
      let valueB = b[field];

      // Convert dates to Date objects for proper comparison
      if (field === 'startDate' || field === 'statusDate') {
        valueA = new Date(valueA); // Convert to Date object
        valueB = new Date(valueB); // Convert to Date object

        // return sorted date fields
        return (valueA <= valueB) ? valueA : valueB;
      }

      // Handle number comparisons (for numeric fields like score)
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }

      // Handle string comparisons (for fields like name, ABO number)
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      }

      // Default case if the field is of unexpected type
      return 0;
    };

    // Sort the list based on the field and direction
    const sortedList = [...aboList].sort((a, b) => {
      const result = compare(a, b);
      return dir === 1 ? result : -result; // Ascending or Descending based on dir
    });

    // Return the sorted list to be used
    return sortedList;
  };

  const sortedAboList = sortAboListRecords();

  return (
    <>
      <div className={styles.aboListing_wrapper}>
        <Header
          headerName={`Course Roster - ${selectedCourse?.primaryTitle}`}
          btnName1="Back to Course Management"
          isBtn1={true}
          handleButton={() => {
            if(isRoasterFilterFormDirty){
              setIsAlertModalOpen(true)
              dispatch(setNavigateTo('/Coursesmanagement'))
            }
            else
              router.push('/Coursesmanagement')}
          }
        />

        <main className={[styles.main_content_wrapper, styles.inner_wrapper].join(' ')}>
          <div className={styles.top_area_wrapper}>
            <div className={styles.sort_by_wrapper}>
              {/* <div className={styles.txt}>Filter Table</div> */}
              <div className={[styles.icon_wrapper].join(' ')}>
                <div className={styles.filter} onClick={() => setShowFilters(!showFilters)} />
                {showFilters && (
                  <FilterComponent
                    isPills={true}
                    filterData={filterData}
                    col6={true}
                    handleFilterApply={(e = []) => {
                      if(e[0] && Object.keys(e[0]).length > 0) {
                        const selectedFilterObj = { ...e[0], checked: true };
                        const sectionData = _initialFilterData?.filtersList[0]?.filterTypeData?.sectionData;
                        
                        if(sectionData?.length) {
                          const indx = sectionData.findIndex(i => i?.id === selectedFilterObj?.id);
                          const sectionDataTemp = sectionData.map(f => ({...f, checked: false}));
                          sectionDataTemp[indx] = selectedFilterObj;

                          const _filterData = {
                            filtersList: [
                              {
                                filterType: 'RADIO',
                                filterTypeData: {
                                  sectionTitle: 'Filter',
                                  sectionData: sectionDataTemp
                                },
                              },
                            ],
                          };

                          setSelectedFilter(selectedFilterObj);
                          setFilterData(_filterData)
                        }
                      }
                    }}
                    handleFilterCancle={() => setShowFilters(false)}
                  />
                )}
              </div>
            </div>

            <DropDown
              list={affCodeList}
              dropdownStyles={{padding: '5px 15px', margin: 'auto', height: 'auto'}}
              onChange={(value) => dispatch(setSelectedAffiliateCode(value))}
              selectedItem={selectedAffCode}
            />

            {SearchBar}

            <DateRangerPicker 
              onDateRangeChange={(range) => setSelectedFilterDate(range)} 
              onClickApplyCb={() => {
                dispatch(setAboListResetPaginationProps());
                dispatch(fetchAboList({ payload: getFetchAboListPayload() }))
              }} 
            />
            <div className={styles.add_addition_icons}>
              <ActionButtonIcon
                actionBtnType={AdditionIcon}
                marginLeft={8}
                handleOpenModal={handleAddNewRoaster}
              />
            </div>
          </div>

          <RoasterFilterModal
            handleOpenAddRecordModal={handleOpenAddRecordModal}
            handleOpenDeleteRecordModal={handleOpenDeleteRecordModal}
            getFetchAboListPayload={getFetchAboListPayload}
            // Using redux states for below code
            // startDate={startDate}
            // setStartDate={setStartDate}
            // statusDate={statusDate}
            // setStatusDate={setStatusDate}
          />

          <div className={styles.learningRecordCardContainer}>
            {sortedAboList?.length > 0 ? sortedAboList?.map((row, index) => (
              <LearningRecordCard key={row?.rowId} row={row} 
              index={index} extractRowKey={(row) => row.rowId} selectedRows={selectedAboList} onSelectionChangeCallBack={(selectedData) => dispatch(setSelectedAboList(selectedData))}
              onHeaderSelectionChangeCallBack={(selectedData) =>
                dispatch(setSelectedAboList(selectedData))
              } />
            )) : <NoRecordsFoundComponent/>}
          </div>

          <div className={styles.richGridContainer}>
            <RichGrid
              isLoading={aboListLoader}
              data={sortedAboList}
              columns={ABO_LISTING_COLUMNS}
              selectable={true}
              extractRowKey={(row) => row.rowId}
              onSelectionChangeCallBack={(selectedData) => dispatch(setSelectedAboList(selectedData))}
              onHeaderSelectionChangeCallBack={(selectedData) =>
                dispatch(setSelectedAboList(selectedData))
              }
              selectedRows={selectedAboList}
              NoRecordsFoundComponent={NoRecordsFoundComponent}
              sortProps={{
                ...aboListSortProps,
                onChange: (updatedSortProps) => dispatch(setAboListSortProps(updatedSortProps)),
              }}
              paginationProps={aboListPaginationProps}
              onClickShowNextRecordsCb={() => {
                dispatch(setAboListPaginationProps({ currentPageNo: aboListPaginationProps?.currentPageNo + 1 }));
              }}
              onClickShowPreviousRecordsCb={() => {
                dispatch(setAboListPaginationProps({ currentPageNo: aboListPaginationProps?.currentPageNo - 1 }));
              }}
              onClickPageNoCb={(selectedPageIndex) => {
                dispatch(setAboListPaginationProps({ currentPageNo: selectedPageIndex + 1 }));
              }}
            />
          </div>

        </main>

        {addNewRoaster && (
          <RoasterNewComponent
            handleCloseAddRecordModal={handleCloseAddRecordModal}
            affCodeList={affCodeList}
            selectedCourse={selectedCourse}
          />
        )}

        {/* Roaster Delete Modal */}
        <DeleteConfirmationModal
          isDeleteRecord={isDeleteRecord}
          handleCloseDeleteModal={() => setDeleteRecord(false)}
          handleDeleteInput={(value) =>
            handleDeleteInput({
              value,
              setInput,
            })
          }
          handleDeleteImage={(value) =>
            handleDeleteInput({
              value,
              setInput,
            })
          }
          input={input}
          handleDeleteRecord={() => handleDeleteRecord()}
          selectedAboList={selectedAboList}
          selectedCourse={selectedCourse?.primaryTitle}
          //  notificationData={notificationData}
          //  ImageDetail={ImageDetail}
          ModalWrapper={ModalWrapper}
          Background={Background}
          //  Notification={Notification}
        />
      </div>

      {isAlertModalopen && <AlertModal onClose={()=>setIsAlertModalOpen(false)} onExit={()=>{
          router.push(navigateTo)
          dispatch(setIsRoasterFilterFormDirty(false))
        }
      }/>}

      {notificationData?.show && <Notification />}
    </>
  );
};

export default AboListing;
