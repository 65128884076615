import React, {useEffect, useState, useRef} from 'react';
import styles from './ManageLearningPath.module.scss';
import Header from '../../shared/header';
import TopBarComponenent from '../../audienceManagement/TopBar';
import {dateIsoToNormalDate} from '../../utils/commonUtil';
import {ReactComponent as ImageNew} from '../../../styles/images/sample_img6.svg';
import CheckBoxBlue from '../../shared/checkboxBlue';
import EmptyScreenComponent from '../../shared/emptyScreen';
import RadioButtonsComponent from '../../shared/radioButtons';
import NewRadioButtonsComponent from '../../shared/newRadioButtonsComponent';
import {useHistory} from 'react-router-dom';
import {makeRequest} from '../../utils/APIsUtils/httpsUtils';
import {
  setNotificationData,
  setDashboardNotificationCountApi,
  setNotificationListApi,
  setCountryList,
} from '../../../redux/actions/uiActions';
import {connect} from 'react-redux';
import findIndex from 'lodash/findIndex';
import Notification from '../../shared/notification';
import LoadingShimmer from '../../shared/LoadingShimmer';
import LoadingShimmerList from '../../shared/LoadingShimmerList';
const ManageCoursesModal = ({
  setNotificationData,
  notificationData,
  setDashboardNotificationCountApi,
  setNotificationListApi,
  currentUserId,
  countryList,
}) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const [isLoading, setIsLoading] = useState(true);
  const [learningPathAPIResponse, setLearningPathAPIResponse] = useState([]);
  const [isCardView, setIsCardView] = useState(true);
  const [isListView, setIsListView] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);
  const [isPopUpClicked, setPopUpClicked] = useState(false);
  const [showAddedCourses, setShowAddedCourses] = useState(true);
  // API state
  const [coursesUIData, setCoursesUiData] = useState([]);
  const [clikedData, setClikedData] = useState([]);
  const [clikedDataCopy, setClikedDataCopy] = useState([]);
  const [audienceDetails, setAudienceDetails] = useState([]);
  const [courseListDefaultFilter, setCourseListDefaultFilter] = useState([]);

  const [coursesUIDataCopy, setCoursesUiDataCopy] = useState([]);
  const [isRadioChecked, setRadioChecked] = useState(false);

  const [aaa, setAaa] = useState('');
  const [bbb, setBbb] = useState('');

  const [totalNumberRemovedCourses, setTotalNumberRemovedCourses] = useState([]);
  const [totalNumberAddedCourses, setTotalNumberAddedCourses] = useState([]);

  const [apiCourseTypeCopy, setApiCourseTypeCopy] = useState([]);
  const [apiCourseType, setApiCourseType] = useState([]);
  const [isCourseTypeChanged, setCourseTypeChanged] = useState(true);

  const [search, setSearch] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [allCourseSearch, setAllCourseSearch] = useState([]);
  const [addedCourseSearch, setAddedCourseSearch] = useState([]);
  const [predictedSearch, setPredictedSearch] = useState([]);
  const [filterAllCoursesData, setFilterAllCoursesData] = useState([]);
  const [filterDropdowncopy, setFilterDropdownopy] = useState(false);
  const [selectedId, setSelectedId] = useState(4);

  const [isChecked, setChecked] = useState(true);
  const [radioButtonData, setRadioButtonData] = useState([]);

  const [width, setWidth] = useState(0);
  const [countryDDList, setCountryDDList] = useState(
    countryList.map((item) => {
      return {
        ...item,
        name: item.countryName,
        id: item.countryCode,
      };
    }),
  );
  const [countrySelected, setCountrySelected] = useState(countryDDList[0]);
  const _initialFilterData = {
    tiles: [
      {
        index: 0,
        id: 'DRAFT',
        title: 'Draft',
        className: 'draft',
        filterParameter: 'learningPathState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 1,
        id: 'LIVE',
        title: 'Live',
        className: 'goLive',
        filterParameter: 'learningPathState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 2,
        id: 'OFFLINE',
        title: 'Offline',
        className: 'goOffline',
        filterParameter: 'learningPathState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 3,
        id: 'ARCHIVE',
        title: 'Archive',
        className: 'archive',
        filterParameter: 'learningPathState',
        typeOfFilter: 'tiles',
        checked: false,
      },
    ],
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 3,
              name: 'Newest To Oldest',
              id: 4,
              checked: false,
              filterParameter: 'launchDate',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 4,
              name: 'Oldest To Newest',
              id: 5,
              checked: false,
              filterParameter: 'launchDate',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 5,
              name: 'Sort by Code (Ascending)',
              id: 6,
              checked: false,
              filterParameter: 'learningPath',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
            },
          ],
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Categories',
          sectionData: [],
        },
      },
    ],
  };

  useEffect(() => {
    /**
     *   fetch new Learning path based on country selected from the DropDown
     */
    fetchAudienceDetailsWithAllAudience();
    setShowAddedCourses(true);
  }, [countrySelected]);

  const [filterData, setFilterData] = useState(_initialFilterData);

  const fetchAudienceDetailsWithAllAudience = async () => {
    let fetchAudId = query?.countryCodeAllIds?.find(
      (country) => country.countryCode === countrySelected.countryCode,
    );
    setIsLoading(true);
    if (fetchAudId) {
      const dataAudience = await makeRequest({
        method: 'GET',
        endpoint: `/admin/api/v1/audience-groups/${fetchAudId?.countryId}/learning-paths`,
      });
      if (dataAudience != 'ERR_NETWORK' && dataAudience) {
        const result = dataAudience?.data?.filter((item) => {
          if (item?.isAdded === true) {
            return item;
          }
        });

        setCoursesUiDataCopy(result);
        setTotalNumberRemovedCourses(result);
        setApiCourseTypeCopy(
          result?.map(({learningPathId, learningPathType}) => ({learningPathId, learningPathType})),
        );
        setApiCourseType(
          result?.map(({learningPathId, learningPathType}) => ({learningPathId, learningPathType})),
        );

        setLearningPathAPIResponse(dataAudience?.data);
        setCourseListDefaultFilter(dataAudience?.data);
        setSearchFilterEmptyState(true);
        setFilterAllCoursesData(dataAudience?.data);
        setSearchList(dataAudience?.data);
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchCategories();
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (
      learningPathAPIResponse &&
      learningPathAPIResponse.length &&
      learningPathAPIResponse.length > 0
    ) {
      let _coursesUIData = [];
      learningPathAPIResponse.map((course) => {
        _coursesUIData.push(getCoursesUIData(course));
      });
      setCoursesUiData(_coursesUIData);
    }
    setClikedData(
      learningPathAPIResponse.filter((el) => {
        return coursesUIDataCopy.some((element) => {
          return element.learningPathId === el.learningPathId;
        });
      }),
    );
    setClikedDataCopy(
      learningPathAPIResponse.filter((el) => {
        return coursesUIDataCopy.some((element) => {
          return element.learningPathId === el.learningPathId;
        });
      }),
    );
  }, [learningPathAPIResponse]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    if (showAddedCourses) {
      // All Courses
      if (search?.length > 0) {
        setCoursesUiData(allCourseSearch);
        setSearchList(allCourseSearch);
      } else {
        if (filterAllCoursesData.length >= 0) {
          setSearchList(filterAllCoursesData);
          setCoursesUiData(filterAllCoursesData);
        } else {
          setSearchList(learningPathAPIResponse);
          setCoursesUiData(learningPathAPIResponse);
        }
      }
    } else if (!showAddedCourses) {
      // Added courses Only
      if (search?.length > 0) {
        setSearchList(filterAllCoursesData);
        setCoursesUiData(filterAllCoursesData);
      } else {
        setSearchList(clikedData);
        setCoursesUiData(clikedData);
      }
    }
  }, [showAddedCourses, clikedData, search]);

  useEffect(() => {
    setSearchList(filterAllCoursesData);
    setCoursesUiData(filterAllCoursesData);
  }, [filterAllCoursesData]);

  useEffect(() => {
    if (search && search.length && search.length >= 1) {
      let allCourseResult;

      allCourseResult = filterAllCoursesData.filter((user) =>
        user.title.toLowerCase().includes(search.toLowerCase()),
      );
      //for checking length of audi group name
      allCourseResult?.sort(function (one, other) {
        return one?.title?.length - other?.title?.length;
      });
      //for checking the serach char index in audi group name
      allCourseResult?.sort(function (one, other) {
        return (
          one.title?.toLowerCase().indexOf(search?.toLowerCase()) -
          other.title?.toLowerCase().indexOf(search?.toLowerCase())
        );
      });
      let addedCourseResult;
      addedCourseResult = clikedData?.filter((user) =>
        user.title.toLowerCase().includes(search.toLowerCase()),
      );
      //for checking length of audi group name
      addedCourseResult?.sort(function (one, other) {
        return one?.title?.length - other?.title?.length;
      });
      //for checking the serach char index in audi group name
      addedCourseResult?.sort(function (one, other) {
        return (
          one.title?.toLowerCase().indexOf(search?.toLowerCase()) -
          other.title?.toLowerCase().indexOf(search?.toLowerCase())
        );
      });

      setAddedCourseSearch(addedCourseResult);
      setAllCourseSearch(allCourseResult);
      showAddedCourses ? setCoursesUiData(allCourseResult) : setCoursesUiData(addedCourseResult);
    }
  }, [search, showAddedCourses, clikedData]);

  useEffect(() => {
    if (audienceDetails?.learningPaths?.length > 0 && clikedDataCopy?.length === 0) {
      setShowAddedCourses(true);
    }
    if (isCardView) {
      setPopUpClicked(true);
    } else if (isListView) {
      setPopUpClicked(true);
    }
  }, [clikedData]);

  const handleClickOutside = (e) => {
    const el = document.getElementById('divPopUp');
    if (el !== null) {
      const outside = !el?.contains(e.target);
      if (outside) {
        if (
          !e.target.className.includes('ManageCourses_course_type_popup') &&
          !e.target.className.includes('ManageCourses_popup_btn_wrapper') &&
          !e.target.className.includes('_icon') &&
          !e.target.className.includes('checkbox_wrapper')
        ) {
          setPopUpClicked(false);
        }
      }
    }
  };
  const handleFilterApply = (params) => {
    setCoursesUiData([]);
    let arrayOfList = [courseListDefaultFilter, clikedDataCopy];
    arrayOfList.forEach((value, index) => {
      let _coursesUIData = [];

      let _coursesAPIResponse = [];
      if (params?.find((ele) => ele?.name === 'Recent Activity')) {
        _coursesAPIResponse = index === 0 ? [...courseListDefaultFilter] : [...clikedDataCopy];
      } else {
        _coursesAPIResponse = index === 0 ? [...learningPathAPIResponse] : [...clikedDataCopy];
      }
      // if (params?.find((ele) => ele?.name === "Recent Activity")) {
      //   if(showAddedCourses){
      //     _coursesAPIResponse = [...courseListDefaultFilter];
      //   }
      //   else{
      //     _coursesAPIResponse = [...clikedDataCopy];
      //   }
      // } else {
      //   if(!showAddedCourses){
      //     _coursesAPIResponse = [...clikedDataCopy];
      //   }
      //   else{
      //     _coursesAPIResponse = [...learningPathAPIResponse];
      //   }
      // }

      let checkBoxFilterArray = params.filter((ele) => ele.typeOfFilter === 'CHECKBOX');
      let checkBoxLables = [];
      let tilesFilterArray = params.filter((ele) => ele.typeOfFilter === 'tiles');
      let tilesLables = [];
      let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');

      if (checkBoxFilterArray !== []) {
        for (const ele of checkBoxFilterArray) {
          if (ele?.checked) {
            checkBoxLables.push(ele?.title);
          }
        }
        const tempSectionData = new Array(...filterData.filtersList[1].filterTypeData.sectionData);
        tempSectionData?.forEach((x) => {
          if (checkBoxLables?.includes(x?.title)) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        filterData.filtersList[1].filterTypeData.sectionData = tempSectionData;
        setFilterData(filterData);
      }

      if (tilesFilterArray !== []) {
        for (const ele of tilesFilterArray) {
          if (ele?.checked) {
            tilesLables.push(ele?.title);
          }
        }

        const tempSectionData = new Array(...filterData.tiles);

        filterData.tiles = tempSectionData;
        setFilterData(filterData);
      }
      if (checkBoxLables?.length > 0) {
        for (let i = 0; i < _coursesAPIResponse?.length; i++) {
          for (let k = 0; k < _coursesAPIResponse[i]?.categories.length; k++) {
            for (let j = 0; j < checkBoxLables.length; j++) {
              if (_coursesAPIResponse[i]?.categories[k] === checkBoxLables[j]) {
                _coursesUIData.push(_coursesAPIResponse[i]);
              }
            }
          }
        }
      } else {
        _coursesUIData = _coursesAPIResponse;
      }

      if (tilesLables?.length > 0) {
        _coursesUIData = _coursesUIData.filter((ele) => {
          return tilesLables.includes(ele?.learningPathState);
        });
      }

      if (radioFilterArray !== []) {
        for (const ele of radioFilterArray) {
          const tempSectionData = new Array(
            ...filterData.filtersList[0].filterTypeData.sectionData,
          );
          tempSectionData?.forEach((x) => {
            if (ele?.name === x?.name) {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
          filterData.filtersList[0].filterTypeData.sectionData = tempSectionData;
          setFilterData(filterData);

          switch (ele?.filterParameterType) {
            case 'ASC':
              if (_coursesUIData?.length > 0) {
                _coursesUIData = _coursesUIData.sort((a, b) => {
                  if (
                    a[ele?.filterParameter]?.toLowerCase() > b[ele?.filterParameter]?.toLowerCase()
                  )
                    return 1;
                  else if (
                    a[ele?.filterParameter]?.toLowerCase() < b[ele?.filterParameter]?.toLowerCase()
                  )
                    return -1;
                  else return 0;
                });
              }
              break;

            case 'DESC':
              if (_coursesUIData?.length > 0) {
                _coursesUIData = _coursesUIData.sort((a, b) => {
                  if (
                    a[ele?.filterParameter]?.toLowerCase() < b[ele?.filterParameter]?.toLowerCase()
                  )
                    return 1;
                  else if (
                    a[ele?.filterParameter]?.toLowerCase() > b[ele?.filterParameter]?.toLowerCase()
                  )
                    return -1;
                  else return 0;
                });
              }
              break;

            case 'TIME-ASC':
              if (_coursesUIData?.length > 0) {
                _coursesUIData = _coursesUIData.sort((a, b) => {
                  if (
                    a[ele?.filterParameter] === undefined ||
                    a?.learningPathState?.toLowerCase() === 'draft'
                  )
                    return 1;
                  else if (
                    b[ele?.filterParameter] === undefined ||
                    b?.learningPathState?.toLowerCase() === 'draft'
                  )
                    return -1;
                  else if (a[ele?.filterParameter] < b[ele?.filterParameter]) return 1;
                  else if (a[ele?.filterParameter] > b[ele?.filterParameter]) return -1;
                  else return 0;
                });
              }
              break;

            case 'TIME-DESC':
              if (_coursesUIData?.length > 0) {
                _coursesUIData = _coursesUIData.sort((a, b) => {
                  if (
                    a[ele?.filterParameter] === undefined ||
                    a?.learningPathState?.toLowerCase() === 'draft'
                  )
                    return 1;
                  else if (
                    b[ele?.filterParameter] === undefined ||
                    b?.learningPathState?.toLowerCase() === 'draft'
                  )
                    return -1;
                  else if (a[ele?.filterParameter] > b[ele?.filterParameter]) return 1;
                  else if (a[ele?.filterParameter] < b[ele?.filterParameter]) return -1;
                  else return 0;
                });
              }
              break;

            case 'default':
              _coursesUIData = _coursesUIData;
              break;
          }
        }
      }
      setCoursesUiData(_coursesUIData);
      //setAllCourseSearch(_coursesUIData)
      index === 1 ? setClikedData(_coursesUIData) : null;
      index === 0 ? setFilterAllCoursesData(_coursesUIData) : null;
    });
  };

  const getCoursesUIData = (course) => {
    const {learningPathId, learningPathState, description, launchDate, thumbnail, title} = course;
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let dateToShowForListView = ' ';
    let dateToShowForCardView = ' ';
    let timeToShow = ' ';
    if (launchDate) {
      const date = new Date(launchDate);
      const hours = date.getHours();
      const mins = date.getMinutes();
      timeToShow = (hours > 12 ? hours - 12 : hours) + ':' + mins + (hours > 12 ? ' pm' : ' am');
      dateToShowForListView =
        months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
      dateToShowForCardView =
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    } else {
      dateToShowForListView = ' ';
      dateToShowForCardView = ' ';
    }

    let className = '';
    switch (learningPathState) {
      case 'Live':
        className = 'live';
        break;
      case 'Offline':
        className = 'offline';
        break;
      case 'Draft':
        className = 'draft';
        break;
      case 'Archive':
        className = 'archive';
        break;
    }

    return {
      learningPathId,
      learningPathState,
      description,
      thumbnail,
      title,
      timeToShow,
      dateToShowForListView,
      dateToShowForCardView,
      className,
      launchDate,
    };
  };

  const closeOpenPopUpButton = (props) => {
    const {e, isCardView, setAaa, isPopUpClicked, setPopUpClicked, setBbb, isListView} = props;
    if (isCardView) {
      setAaa(e.currentTarget.id);
      setPopUpClicked(!isPopUpClicked);
    } else if (isListView) {
      setBbb(e.currentTarget.id);
      setPopUpClicked(!isPopUpClicked);
    }
  };

  const handleCloseButton = () => {
    router.push({
      pathname: '/UserManagement',
      query: {
        isManageCourseOpen: false,
        UserGroupId: query?.AudienceGroupId,
      },
    });
    if (
      !(
        coursesUIDataCopy?.length - totalNumberRemovedCourses?.length === 0 &&
        totalNumberAddedCourses?.length === 0
      )
    ) {
      if (width > 992) {
        setNotificationData({
          show: true,
          type: 'FAILURE',
          title: 'User Group Changes have not been saved',
          description: `Learning Paths have not been updated in the ${audienceDetails?.audienceGrpName}.`,
        });
      } else {
        setNotificationData({
          show: true,
          type: 'FAILURE',
          title: 'User Group Edit Failed',
          description: `Learning Paths have not been updated in ${audienceDetails?.audienceGrpName}.`,
        });
      }
    }
  };

  const handleCourseAddRemove = (e) => {
    //For removing course
    setAaa(e?.currentTarget?.id);
    setBbb(e?.currentTarget?.id);
    if (clikedData?.some((ele) => ele?.learningPathId === e.currentTarget.id)) {
      setClikedData(
        clikedData?.filter((item) => {
          //For Count of Removed courses
          if (
            coursesUIDataCopy?.some((ele) => ele?.learningPathId === e.currentTarget.id) &&
            clikedData?.some((ele) => ele?.learningPathId === e.currentTarget.id)
          ) {
            setTotalNumberRemovedCourses(
              totalNumberRemovedCourses?.filter((item) => {
                return item?.learningPathId !== e.currentTarget.id;
              }),
            );
          }
          if (
            totalNumberAddedCourses?.some((ele) => ele?.learningPathId === e.currentTarget.id) &&
            clikedData?.some((ele) => ele?.learningPathId === e.currentTarget.id)
          ) {
            setTotalNumberAddedCourses(
              totalNumberAddedCourses?.filter((item) => {
                return item?.learningPathId !== e.currentTarget.id;
              }),
            );
          }
          return item?.learningPathId !== e.currentTarget.id;
        }),
      );
      setClikedDataCopy(
        clikedDataCopy?.filter((item) => {
          //For Count of Removed courses
          if (
            coursesUIDataCopy?.some((ele) => ele?.learningPathId === e.currentTarget.id) &&
            clikedData?.some((ele) => ele?.learningPathId === e.currentTarget.id)
          ) {
            setTotalNumberRemovedCourses(
              totalNumberRemovedCourses?.filter((item) => {
                return item?.learningPathId !== e.currentTarget.id;
              }),
            );
          }
          if (
            totalNumberAddedCourses?.some((ele) => ele?.learningPathId === e.currentTarget.id) &&
            clikedData?.some((ele) => ele?.learningPathId === e.currentTarget.id)
          ) {
            setTotalNumberAddedCourses(
              totalNumberAddedCourses?.filter((item) => {
                return item?.learningPathId !== e.currentTarget.id;
              }),
            );
          }
          return item?.learningPathId !== e.currentTarget.id;
        }),
      );
    }
    //For Adding Courses
    else if (!clikedData?.some((ele) => ele?.learningPathId === e.currentTarget.id)) {
      //For Count of Removed courses
      if (
        coursesUIDataCopy?.some((ele) => ele?.learningPathId === e.currentTarget.id) &&
        !clikedData?.some((ele) => ele?.learningPathId === e.currentTarget.id)
      ) {
        let b = coursesUIDataCopy?.filter((item) => {
          return item?.learningPathId === e.currentTarget.id;
        });
        setTotalNumberRemovedCourses((prev) => [...prev, b[0]]);
      }
      if (
        !coursesUIDataCopy?.some((ele) => ele?.learningPathId === e.currentTarget.id) &&
        !totalNumberAddedCourses?.some((ele) => ele?.learningPathId === e.currentTarget.id)
      ) {
        let c = learningPathAPIResponse?.filter((item) => {
          return item?.learningPathId === e.currentTarget.id;
        });
        setTotalNumberAddedCourses((prev) => [...prev, c[0]]);
      }
      let a = learningPathAPIResponse?.filter((item) => {
        return item?.learningPathId === e.currentTarget.id;
      });
      setClikedData((prev) => [...prev, a[0]]);
      setClikedDataCopy((prev) => [...prev, a[0]]);
    }
  };

  const handleToggleSwitch = () => {
    setShowAddedCourses(!showAddedCourses);
    if (width <= 767 && showAddedCourses) {
      setNotificationData({
        show: true,
        type: 'UPLOADING',
        title: 'Showing Only Added Learning Paths',
        singleLine: true,
      });
    }
  };

  const handleSaveChanges = async () => {
    let fetchAudId = query?.countryCodeAllIds?.find(
      (country) => country.countryCode === countrySelected.countryCode,
    );
    let countryCodeAllIds = query?.countryCodeAllIds;
    let learningPaths = clikedDataCopy?.map(({learningPathId, learningPathType}) => ({
      learningPathId,
      learningPathType,
    }));

    let addRemoveAudience = {learningPaths};
    const _endpoint = `/admin/api/v1/audience-groups/${fetchAudId?.countryId}/learning-paths`;
    let dataUpdateImageDetails = await makeRequest({
      method: 'PATCH',
      endpoint: _endpoint,
      body: addRemoveAudience,
    });
    if (dataUpdateImageDetails?.status === 'Success') {
      router.push({
        pathname: '/UserManagement',
        query: {
          isManageCourseOpen: false,
          UserGroupId: countryCodeAllIds,
        },
      });
      setDashboardNotificationCountApi(currentUserId);
      setNotificationListApi(currentUserId);
      totalNumberAddedCourses?.length > 0 &&
      coursesUIDataCopy?.length - totalNumberRemovedCourses?.length === 0
        ? setNotificationData({
            show: true,
            type: 'SUCCESS',
            title: 'User Group Edited Successfully',
            description:
              totalNumberAddedCourses?.length === 1
                ? `${totalNumberAddedCourses?.length} Learning Path Added to the ${query?.AudienceGrpName}.`
                : `${totalNumberAddedCourses?.length} Learning Paths Added to the ${query?.AudienceGrpName}.`,
          })
        : totalNumberAddedCourses?.length === 0 &&
          coursesUIDataCopy?.length - totalNumberRemovedCourses?.length > 0
        ? setNotificationData({
            show: true,
            type: 'SUCCESS',
            title: 'User Group Edited Successfully',
            description:
              coursesUIDataCopy?.length - totalNumberRemovedCourses?.length === 1
                ? `${
                    coursesUIDataCopy?.length - totalNumberRemovedCourses?.length
                  } Learning Path Removed from the ${query?.AudienceGrpName}.`
                : `${
                    coursesUIDataCopy?.length - totalNumberRemovedCourses?.length
                  } Learning Paths Removed from the ${query?.AudienceGrpName}.`,
          })
        : totalNumberAddedCourses?.length > 0 &&
          coursesUIDataCopy?.length - totalNumberRemovedCourses?.length > 0
        ? setNotificationData({
            show: true,
            twoLineDescription: true,
            type: 'SUCCESS',
            title: 'User Group Edited Successfully',
            description:
              totalNumberAddedCourses?.length === 1
                ? `${totalNumberAddedCourses?.length} Learning Path Added to the ${query?.AudienceGrpName}.`
                : `${totalNumberAddedCourses?.length} Learning Paths Added to the ${query?.AudienceGrpName}.`,
            lineTwoDescription:
              coursesUIDataCopy?.length - totalNumberRemovedCourses?.length === 1
                ? `${
                    coursesUIDataCopy?.length - totalNumberRemovedCourses?.length
                  } Learning Path Removed from the ${query?.AudienceGrpName}.`
                : `${
                    coursesUIDataCopy?.length - totalNumberRemovedCourses?.length
                  } Learning Paths Removed from the ${query?.AudienceGrpName}.`,
          })
        : null;
    }
  };

  const handleRadioButtomOnClick = (course, item) => {
    const indexOf = apiCourseType?.findIndex((obj) => {
      return obj?.learningPathId === course?.learningPathId;
    });
    if (indexOf !== -1) {
      apiCourseTypeCopy[indexOf].learningPathType = item?.title;
    }
    setCourseTypeChanged(
      apiCourseTypeCopy.length === apiCourseType.length &&
        apiCourseTypeCopy.every(
          (o, i) =>
            Object.keys(o).length === Object.keys(apiCourseType[i]).length &&
            Object.keys(o).every((k) => o[k] === apiCourseType[i][k]),
        ),
    );
    switch (item?.title) {
      case 'Required':
        course.learningPathType = 'Required';
        setSelectedId(1);
        break;
      case 'Recommended':
        course.learningPathType = 'Recommended';
        setSelectedId(2);
        break;
      case 'Quick Start':
        course.learningPathType = 'Quick Start';
        setSelectedId(3);
        break;
      case 'General visibility':
        course.learningPathType = 'General visibility';
        setSelectedId(4);
        break;
      default:
        setSelectedId(4);
        break;
    }
    setPopUpClicked(false);
  };

  const handleSearchInput = (event) => {
    setSearch(event?.target?.value);
    setPredictedSearch([]);

    let tempPredition = coursesUIData?.map((item) => ({
      id:
        item?.learningPathId && item?.learningPathId !== null && item.learningPathId !== undefined
          ? item?.learningPathId
          : '',
      label: item?.title && item?.title !== null && item.title !== undefined ? item?.title : '',
    }));
    if (tempPredition.length >= 0 && event?.target?.value?.length >= 0) {
      setPredictedSearch(tempPredition ? tempPredition : {});
    } else {
      setPredictedSearch([]);
    }
  };

  const mapCoursesToAudience = (course) => {
    if (course?.learningPathType === undefined || course?.learningPathType === '') {
      course.learningPathType = 'General visibility';
    }

    const radioButtonData = [
      {
        id: 1,
        title: 'Required',
        className: 'req_icon',
        checked: false,
        typeOfFilter: 'RADIO',
      },
      {
        id: 2,
        title: 'Recommended',
        className: 'recom_icon',
        checked: false,
        typeOfFilter: 'RADIO',
      },
      // {
      //   id: 3,
      //   title: 'Quick Start',
      //   className: 'qstart_icon',
      //   checked: false,
      //   typeOfFilter: 'RADIO',
      // },
      {
        id: 4,
        title: 'General visibility',
        className: 'gen_visiblity_icon',
        checked: false,
        typeOfFilter: 'RADIO',
      },
    ];
    switch (course?.learningPathType) {
      case 'Required':
        setSelectedId(1);
        break;
      case 'Recommended':
        setSelectedId(2);
        break;
      case 'Quick Start':
        setSelectedId(3);
        break;
      case 'General visibility':
        setSelectedId(4);
        break;
    }
    // return radioButtonData
    setRadioButtonData(radioButtonData);
  };

  const fetchCategories = async () => {
    const dataCategories = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/categories',
    });
    if (dataCategories) {
      let _filterData = filterData;
      let _filtersList = _filterData.filtersList;
      let _tempFiltersList = [];
      let index = findIndex(_filtersList, ['filterType', 'CHECKBOX']);
      if (index !== -1) {
        dataCategories?.data?.length &&
          dataCategories?.data?.length > 0 &&
          dataCategories?.data?.map((dataItem, _index) => {
            const {id = '', title = ''} = dataItem;
            _tempFiltersList.push({
              index: _index,
              id: id,
              label: title,
              title: title,
              filterParameter: 'category',
              checked: false,
              typeOfFilter: 'CHECKBOX',
            });
          });
        _filtersList[index].filterTypeData.sectionData = _tempFiltersList;
      }
      setFilterData(_filterData);
    }
  };

  const handleCheckClick = () => {
    setChecked(!isChecked);
  };

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  const dateIsoToDate = (dateIso) => {
    const monthArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    const date = new Date(dateIso);
    return `${monthArray[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const dateIsoToTime = (dateIso) => {
    const date = new Date(dateIso);
    let hours =
      Number(date.getHours().toString()) > 12
        ? Number(date.getHours().toString()) - 12
        : date.getHours();

    hours = hours > 9 ? hours : '0' + hours;
    let minutes =
      Number(date.getMinutes()) > 9 ? Number(date.getMinutes()) : '0' + Number(date.getMinutes());
    const timeFormat = Number(date.getHours().toString()) > 12 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${timeFormat}`;
  };

  return (
    <React.Fragment>
      {query?.isManageCourseOpen && (
        <div className={[styles.modal_wrapper].join(' ')}>
          <div className={[styles.course_mgt_wrapper, ''].join(' ')}>
            {/* Header Component */}
            <Header
              headerName={` ${query?.AudienceGrpName}`}
              headerXSName=" "
              btnName1="Cancel"
              btnName2="Save changes"
              isHeaderXS={false}
              isBtn1={true}
              isBtn2={true}
              isProgressBar={false}
              isSideNavigationPresent={false}
              handleButton={handleCloseButton}
              handleSaveChanges={handleSaveChanges}
              isBtn2Disable={
                coursesUIDataCopy?.length - totalNumberRemovedCourses?.length === 0 &&
                totalNumberAddedCourses?.length === 0 &&
                isCourseTypeChanged === true
              }
              plainTitleWithBold={true}
              planiText={
                query?.CourseCount != 0 ? 'Manage Learning Paths for ' : 'Add Learning Paths to'
              }
            />
            {/* Header Component */}

            {/* <div className={[styles.audience_wrapper, styles.is_drawer_open].join(" ")}> */}
            <div className={[styles.audience_wrapper, styles.course_inner_wrapper, ''].join(' ')}>
              {/* <SeverErrorComponent /> */}
              <div className={[styles.main_wrapper, ''].join(' ')}>
                <TopBarComponenent
                  showGridListSwitch={true}
                  showRoundToggler={true}
                  isCardView={isCardView}
                  isListView={isListView}
                  showAddedLP={true}
                  showAddedCourses={showAddedCourses}
                  setShowAddedCourses={setShowAddedCourses}
                  setIsCardView={setIsCardView}
                  setIsListView={setIsListView}
                  handleToggleSwitch={handleToggleSwitch}
                  clikedData={clikedData}
                  coursesUIDataCopy={coursesUIDataCopy}
                  placeholderName={'Search Learning Path Name'}
                  list={searchList}
                  searchListFunction={(e) => {
                    if (e != '') {
                      setSearch(e?.target?.value?.trim());
                    } else if (e == '') {
                      setSearch('');
                    }
                  }}
                  setPredictedSearch={setPredictedSearch}
                  predictedSearch={predictedSearch}
                  handleSearchInput={handleSearchInput}
                  search={search}
                  filterData={filterData}
                  handleFilterApply={handleFilterApply}
                  isOpenSubCategories={false}
                  setFilterDropdownopy={setFilterDropdownopy}
                  isManageCourseScreen={true}
                  countryList={countryDDList}
                  setCountrySelected={setCountrySelected}
                  toggleBtnLbl={'Show Only Added Learning Paths'}
                  remoteConfig={query?.remoteConfig}
                />
                {/* Notification bar */}
                {/* <div className={}></div> */}
                {/* END of notification bar */}
                <div className={styles.inner_wrapper}>
                  {/* Card View Started  */}
                  {width >= 767 && isCardView ? (
                    <div
                      className={[
                        selectedCourse && selectedCourse?.learningPathId
                          ? styles.card_wrapper
                          : styles.card_wrapper,
                        'bootstrap-wrapper',
                      ].join(' ')}
                    >
                      {isLoading ? (
                        <div className={styles.row_wrapper}>
                          <LoadingShimmer
                            count={20}
                            isLearningPath={false}
                            isLpLandingPage={true}
                          />
                        </div>
                      ) : (
                        <div className={styles.row_wrapper}>
                          {coursesUIData?.length > 0 ? (
                            coursesUIData.map((course, index) => {
                              const {
                                learningPathId,
                                learningPathState,
                                description,
                                timeToShow,
                                dateToShowForCardView,
                                thumbnail,
                                title,
                                className,
                                courses,
                              } = course;
                              return (
                                <div
                                  key={index}
                                  id={learningPathId}
                                  className={[styles.card_holder, ''].join(' ')}
                                  style={
                                    !filterDropdowncopy && learningPathId === aaa
                                      ? {zIndex: '2'}
                                      : null
                                  }
                                >
                                  {/* Selected class should be coditionally added for darker shadow to the card */}
                                  <div
                                    id={learningPathId}
                                    className={[styles.card, styles.selected].join(' ')}
                                  >
                                    {/* Course type popup btn */}
                                    {clikedData?.some(
                                      (e) => e?.learningPathId === learningPathId,
                                    ) && (
                                      <div className={styles.popup_btn_wrapper}>
                                        <div
                                          id={learningPathId}
                                          className={[
                                            styles.course_type_icon,
                                            course?.learningPathType == 'Quick Start'
                                              ? [styles.qstart_icon].join(' ')
                                              : course?.learningPathType == 'Required'
                                              ? [styles.req_icon].join(' ')
                                              : course.learningPathType == 'General visibility'
                                              ? [styles.gen_visiblity_icon].join(' ')
                                              : course.learningPathType == 'Recommended'
                                              ? [styles.recom_icon].join(' ')
                                              : [styles.gen_visiblity_icon].join(' '),
                                          ].join(' ')}
                                          onClick={(e) => {
                                            closeOpenPopUpButton({
                                              e,
                                              isCardView,
                                              setAaa,
                                              isPopUpClicked,
                                              setPopUpClicked,
                                              setBbb,
                                              isListView,
                                            });
                                            mapCoursesToAudience(course);
                                          }}
                                        ></div>
                                        {isPopUpClicked && learningPathId === aaa ? (
                                          <div className={styles.course_type_popup} id={'divPopUp'}>
                                            <div className={styles.title}>Learning Path Type</div>
                                            <div className={styles.course_types}>
                                              {/* Add selected state for Every course type */}
                                              {radioButtonData?.map((item, index) => {
                                                return (
                                                  <div
                                                    className={[styles.item_wrapper].join(' ')}
                                                    key={index}
                                                  >
                                                    <div className={styles.radio_btn_wrapper}>
                                                      <NewRadioButtonsComponent
                                                        selectedId={selectedId}
                                                        onChangeHandler={() => {
                                                          handleRadioButtomOnClick(course, item);
                                                        }}
                                                        item={item}
                                                      />
                                                    </div>
                                                    <div className={styles.item}>{item.title}</div>
                                                    <div
                                                      className={[
                                                        styles.course_type_icon,
                                                        item?.title == course?.learningPathType
                                                          ? styles.selected
                                                          : '',
                                                        item?.title == 'Quick Start'
                                                          ? styles.qstart_icon
                                                          : item?.title == 'Required'
                                                          ? styles.req_icon
                                                          : item?.title == 'General visibility'
                                                          ? styles.gen_visiblity_icon
                                                          : item?.title == 'Recommended'
                                                          ? styles.recom_icon
                                                          : '',
                                                        styles[item.className],
                                                      ].join(' ')}
                                                    ></div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    )}
                                    {/* for unchecked card  */}
                                    <div
                                      id={learningPathId}
                                      className={`${styles.uncheck_card_wrapper}`}
                                      onClick={(e) => {
                                        handleCourseAddRemove(e), mapCoursesToAudience(course);
                                      }}
                                    >
                                      <div className={`${styles.unchecked_box}`}></div>
                                    </div>
                                    {/* or add above an below code conditionally post selection */}
                                    {/* for checked card */}
                                    {clikedData?.some(
                                      (e) => e?.learningPathId === learningPathId,
                                    ) ? (
                                      <div
                                        id={learningPathId}
                                        className={`${styles.checked_card_wrapper}`}
                                        onClick={(e) => {
                                          handleCourseAddRemove(e);
                                        }}
                                      >
                                        <div className={`${styles.checked_box}`}></div>
                                        <div className={`${styles.text}`}>Added</div>
                                      </div>
                                    ) : null}
                                    <div className={styles.card_top}>
                                      <div className={styles.checkbox_container}>
                                        <div className={styles.txt}></div>
                                      </div>
                                      <div
                                        className={[styles.img_wrapper, styles.empty_state].join(
                                          ' ',
                                        )}
                                      >
                                        {thumbnail ? (
                                          <img className={styles.img} src={thumbnail} />
                                        ) : (
                                          <div
                                            className={[styles.img, styles.empty_state_img].join(
                                              ' ',
                                            )}
                                          ></div>
                                        )}
                                      </div>
                                    </div>
                                    <div className={styles.card_bottom}>
                                      <div
                                        className={[styles.status_wrapper, styles.archive_cd].join(
                                          ' ',
                                        )}
                                      >
                                        <div
                                          className={`${styles.status} ${
                                            styles[course?.learningPathState?.toLowerCase()]
                                          }`}
                                        >
                                          <div className={styles.status_txt}>
                                            {learningPathState || 'NA'}
                                          </div>
                                        </div>
                                        {learningPathState !== 'Draft' && (
                                          <div className={styles.text_wrapper}>
                                            {/* Please add other different calendar icon for different status */}
                                            <div
                                              className={
                                                learningPathState === 'Live'
                                                  ? styles.calendar_icon
                                                  : learningPathState === 'Offline'
                                                  ? styles.offline_cal_icon
                                                  : styles.archive_cal_icon
                                              }
                                            ></div>
                                            <div className={styles.date_text}>
                                              {course?.launchDate
                                                ? dateIsoToNormalDate(course?.launchDate)
                                                : 'NA'}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className={styles.description}>{title || 'NA'}</div>
                                      <div className={styles.course_wrapper}>
                                        <div className={styles.courses_icon} />
                                        <div className={styles.number}>{courses}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : isSearchFilterEmptyState ? (
                            <EmptyScreenComponent />
                          ) : null}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className={[styles.table_main_wrapper, styles.table_reduced_width].join(' ')}
                    >
                      <div className={styles.table_container}>
                        <div className={styles.tbl_header}>
                          <div className={styles.tbl_head}>Thumbnail</div>
                          <div className={styles.tbl_head_wrapper}>
                            <div className={styles.tbl_head}>Learning Path Name</div>
                            <div className={styles.tbl_head}>
                              <span className={styles.courses_icon} />
                              <span className={styles.course_title}>Courses</span>
                            </div>
                            <div className={styles.tbl_head}>Upload date</div>

                            {/* <div className={styles.tbl_head}>
                          <div className={styles.active_user_icon}></div>
                          <span className={styles.user_txt}>Users</span>
                        </div> */}
                            <div className={styles.tbl_head}>Status</div>
                            <div className={styles.tbl_head}>Type</div>
                          </div>
                        </div>
                        {coursesUIData?.length > 0 && !isLoading ? (
                          <div className={styles.tbl_body}>
                            <div className={[styles.tbl_row_wrapper].join(' ')}>
                              {/* Please add selected state/class(selected) for row here */}
                              {coursesUIData && coursesUIData.length > 0
                                ? coursesUIData.map((course, index) => {
                                    const {
                                      learningPathId,
                                      learningPathState,
                                      description,
                                      timeToShow,
                                      dateToShowForCardView,
                                      thumbnail,
                                      title,
                                      className,
                                      courses,
                                    } = course;
                                    return (
                                      <div
                                        id={learningPathId}
                                        className={[
                                          styles.row_outer_wrapper,
                                          clikedData?.some(
                                            (e) => e?.learningPathId === learningPathId,
                                          )
                                            ? styles.selected
                                            : '',
                                        ].join(' ')}
                                        key={index}
                                      >
                                        <div
                                          className={[
                                            styles.tbl_row,
                                            // styles.selected,
                                          ].join(' ')}
                                          // onClick={() => fetchSelectedCourse(learningPathId)}
                                        >
                                          <div
                                            id={learningPathId}
                                            className={`${styles.checkbox_wrapper} ${
                                              clikedData?.some(
                                                (e) => e?.learningPathId === learningPathId,
                                              )
                                                ? styles.active
                                                : null
                                            }`}
                                            onClick={(e) => {
                                              handleCourseAddRemove(e),
                                                mapCoursesToAudience(course);
                                            }}
                                          >
                                            <div className={`${styles.check_box}`}></div>
                                          </div>

                                          <div className={styles.tbl_data}>
                                            <div className={styles.inner_data}>
                                              {thumbnail ? (
                                                <img className={styles.img} src={thumbnail} />
                                              ) : (
                                                <div
                                                  className={[
                                                    // styles.img,
                                                    styles.empty_state_img,
                                                  ].join(' ')}
                                                ></div>
                                              )}
                                            </div>
                                          </div>
                                          <div className={styles.tbl_data_wrapper}>
                                            <div
                                              id={learningPathId}
                                              className={styles.tbl_data}
                                              onClick={(e) => {
                                                handleCourseAddRemove(e),
                                                  mapCoursesToAudience(course);
                                              }}
                                            >
                                              <CheckBoxBlue
                                                isChecked={clikedDataCopy?.some(
                                                  (e) => e?.learningPathId === learningPathId,
                                                )}
                                                handleCheckClick={handleCheckClick}
                                              />
                                            </div>
                                            <div className={styles.tbl_data}>
                                              <div className={styles.inner_data}>
                                                <div className={[styles.txt, ''].join(' ')}>
                                                  {title || 'NA'}
                                                </div>
                                              </div>
                                            </div>
                                            <div className={styles.tbl_data}>
                                              <div className={styles.inner_data}>
                                                <div className={[styles.txt, ''].join(' ')}>
                                                  {courses}
                                                </div>
                                              </div>
                                            </div>
                                            <div className={styles.tbl_data}>
                                              {learningPathState === 'Draft' && (
                                                <div className={[styles.txt].join(' ')}>
                                                  <div>
                                                    {course?.launchDate
                                                      ? dateIsoToTime(course?.launchDate)
                                                      : 'NA'}
                                                  </div>
                                                </div>
                                              )}
                                              {learningPathState !== 'Draft' && (
                                                <div className={[styles.txt].join(' ')}>
                                                  <div>
                                                    {course?.launchDate
                                                      ? dateIsoToTime(course?.launchDate)
                                                      : 'NA'}
                                                  </div>
                                                  <div>
                                                    {course?.launchDate
                                                      ? dateIsoToDate(course?.launchDate)
                                                      : 'NA'}
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            <div className={styles.tbl_data}>
                                              <div className={styles.inner_data}>
                                                <div
                                                  className={`${styles.status} ${
                                                    styles[course?.learningPathState?.toLowerCase()]
                                                  }`}
                                                >
                                                  <div className={styles.status_txt}>
                                                    {learningPathState || 'NA'}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className={styles.tbl_data}>
                                              <div className={styles.inner_data}>
                                                {/* Please add here "type_selected" class when course type is added it should be activated */}
                                                {clikedData?.some(
                                                  (e) => e?.learningPathId === learningPathId,
                                                ) && (
                                                  <div
                                                    className={[
                                                      styles.type_wrapper,
                                                      styles.type_selected,
                                                    ].join(' ')}
                                                  >
                                                    <div
                                                      id={learningPathId}
                                                      className={[
                                                        styles.course_type_icon,
                                                        course?.learningPathType == 'Quick Start'
                                                          ? [styles.qstart_icon].join(' ')
                                                          : course?.learningPathType == 'Required'
                                                          ? [styles.req_icon].join(' ')
                                                          : course.learningPathType ==
                                                            'General visibility'
                                                          ? [styles.gen_visiblity_icon].join(' ')
                                                          : course.learningPathType == 'Recommended'
                                                          ? [styles.recom_icon].join(' ')
                                                          : [styles.gen_visiblity_icon].join(' '),
                                                      ].join(' ')}
                                                      onClick={(e) => {
                                                        closeOpenPopUpButton({
                                                          e,
                                                          isCardView: false,
                                                          setAaa,
                                                          isPopUpClicked,
                                                          setPopUpClicked,
                                                          setBbb,
                                                          isListView: true,
                                                        });
                                                        mapCoursesToAudience(course);
                                                      }}
                                                    ></div>
                                                    {/* coursepopoup */}
                                                    {isPopUpClicked && learningPathId === bbb ? (
                                                      <div className={styles.course_type_popup}>
                                                        <div
                                                          className={styles.custom_overlay}
                                                        ></div>
                                                        <div
                                                          className={styles.popup_wrapper}
                                                          id={'divPopUp'}
                                                        >
                                                          <div
                                                            className={styles.mob_bar}
                                                            onClick={(e) => {
                                                              closeOpenPopUpButton({
                                                                e,
                                                                isCardView,
                                                                setAaa,
                                                                isPopUpClicked,
                                                                setPopUpClicked,
                                                                setBbb,
                                                                isListView,
                                                              });
                                                            }}
                                                          >
                                                            <div className={styles.line_bar}></div>
                                                          </div>
                                                          <div className={styles.title}>
                                                            Learning Path Type
                                                          </div>
                                                          <div className={styles.course_types}>
                                                            {/* Add selected state for Every course type */}
                                                            {radioButtonData?.map((item, index) => {
                                                              return (
                                                                <div
                                                                  className={[
                                                                    styles.item_wrapper,
                                                                  ].join(' ')}
                                                                  key={index}
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.radio_btn_wrapper
                                                                    }
                                                                  >
                                                                    <NewRadioButtonsComponent
                                                                      selectedId={selectedId}
                                                                      onChangeHandler={() => {
                                                                        handleRadioButtomOnClick(
                                                                          course,
                                                                          item,
                                                                        );
                                                                      }}
                                                                      item={item}
                                                                    />
                                                                  </div>
                                                                  <div className={styles.item}>
                                                                    {item.title}
                                                                  </div>
                                                                  <div
                                                                    className={[
                                                                      styles.course_type_icon,
                                                                      item?.title ==
                                                                      course?.learningPathType
                                                                        ? styles.selected
                                                                        : '',
                                                                      item?.title == 'Quick Start'
                                                                        ? styles.qstart_icon
                                                                        : item?.title == 'Required'
                                                                        ? styles.req_icon
                                                                        : item?.title ==
                                                                          'General visibility'
                                                                        ? styles.gen_visiblity_icon
                                                                        : item?.title ==
                                                                          'Recommended'
                                                                        ? styles.recom_icon
                                                                        : '',
                                                                      styles[item.className],
                                                                    ].join(' ')}
                                                                  ></div>
                                                                </div>
                                                              );
                                                            })}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        ) : isSearchFilterEmptyState && !isLoading ? (
                          <EmptyScreenComponent />
                        ) : null}
                        {isLoading ? (
                          <div className={styles.tbl_body}>
                            <div className={[styles.tbl_row_wrapper].join(' ')}>
                              <LoadingShimmerList count={20} />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {notificationData?.show && <Notification />}
    </React.Fragment>
  );
};

ManageCoursesModal.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  notificationData: state && state.uiState && state.uiState.notificationData,
  countryList: state && state.uiState && state.uiState.countryList,
});
const mapDispatchToProps = (dispatch) => ({
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
  setCountryList: (options) => dispatch(setCountryList(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ManageCoursesModal));
