import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styles from '../CourseWizard.module.scss';
import AdaptFileModalComponent from '../AdaptFileModal';
import NoteCardComponent from '../../../shared/noteCard';
import CardNewComponent from '../../../shared/cardNewComponent';
import CheckboxNew from '../../../shared/checkboxNew';
import Pills from '../../../shared/pills';
import DeleteModalComponent from '../../../audienceManagement/DeleteModal';
import LoadingShimmerCustom from '../../../shared/LoadingShimmerCustom';
// import TranslationEditLanguage from '../../../translationEditModal';
import CountrySelection from '../../../shared/countrySelection';
import {setCountryList, setLearningPath} from '../../../../redux/actions/uiActions';
import {connect} from 'react-redux';
import CountrySelectionHandleEvents from '../../../utils/CountrySelection/countrySelectionHandleEvents';
import TranslationEditLanguage from '../../../translationLanguages';
const EditWizard = ({
  handleCountrySelection,
  openAdaptFileModal,
  adaptFileState,
  fileDataTemp,
  fileDataTempHindi,
  data,
  setOpenAdaptFileModal,
  openInNewTab,
  setFileData,
  regexNameChinese,
  handleDataFill,
  addUserGroupList,
  deleteUserGroupList,
  disableStateUserManagement,
  handleDeleteUserTargetingGroups,
  handleDeleteUserGroups,
  disableClickBanner,
  handleFileUpload,
  adaptFileButtonState,
  uploadingFileButton,
  fileData,
  isCourseIDFetched,
  mainImageBackup,
  setImageData,
  setThumbnailImage169,
  setCourseMode,
  setNewCourseData,
  setNewCourseWizardState,
  uploadImageFromDesktop,
  uploadNewImagePath,
  setNewThumbnailImageData,
  thumbnailImage169,
  previewAdaptFileCreateNewCourse,
  previewData,
  categories,
  categoryData,
  setCategoryData,
  skills,
  skillsRef,
  setPillsDataSkills,
  pillsDataSkills,
  pillsAddAudienceGroup,
  pillsDataKeyword,
  filterListById,
  handleAddUserTargetingGroups,
  setNotificationData,
  newCourseID,
  addUserGroupListCopy,
  publishButtonAfterPreview,
  keyword,
  keywordsRef,
  setPillsDataKeyword,
  SearchListComponent,
  fetchSelectedCourse,
  setOpenDecommission,
  openDecommission,
  setIsArchive,
  isArchive,
  changeCourseStatus,
  closeCourseWizard,
  isLoading,
  uploadingFileButtonHindi,
  regexNameHindi,
  handleDataFillMultiLingual,
  adaptFileButtonStateHindi,
  fileDataHindi,
  countryList,
  setCountryList,
  replaceFile = false,
  remoteConfig,
  isDuplicateCourseName = false,
  missingFilesMessage,
  setLearningPath,
  courseCodeErrorMessage = ''
}) => {
  const {updateCountryList, handleSelectAllCountry, handleClearAllCountry, handleCountrySelect} =
    CountrySelectionHandleEvents();
  const router = useHistory();
  const {query = {}} = router.location;
  const [localState, setLocalState] = useState('');
  const [configData, setConfigData] = useState({});

  useEffect(() => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    setConfigData(configData);
  }, []);
  const getShimmerList = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(<LoadingShimmerCustom className={[styles.card_holder_ugm_section].join(' ')} />);
    }
    return content;
  };
  const disablehostingBanner = () => {
    setNotificationData({
      show: true,
      type: 'FAILURE',
      title: 'No Action can be taken during File Uploading',
      description:
        'All the buttons are disabled when the file is uploading. Please wait for the file to upload to take any action.',
    });
  };
  const handleCountryClearAll = () => {
    const newCountryList = handleClearAllCountry(countryList);
    setCountryList(newCountryList);
    handleCountrySelection(newCountryList);
  };
  const handleCountrySelectAll = () => {
    const newCountryList = handleSelectAllCountry(countryList);
    setCountryList(newCountryList);
    handleCountrySelection(newCountryList);
  };

  const handleCountrySelectA = (country) => {
    const newCountryList = handleCountrySelect(country, countryList);
    setCountryList(newCountryList);
    let nxtDisable = newCountryList.some((item) => item.checked);
    handleCountrySelection(newCountryList);
  };

  const disabledEditBannerMessage = () => {
    setNotificationData({
      show: true,
      type: 'FAILURE',
      title: 'Editing Course.',
      description:
        'Editing is limited to Course Title, Description, Categories, Skills, Tags, and Thumbnails when a course is Live. To make edits to additional sections of this course, change the course to offline mode in the Course Details.',
    });
  };
  return (
    <div
      className={[
        styles.inner_wrapper,
        styles.edit_course_wrapper,
        styles.preview_adapt,
        styles.overflow_hide,
        '',
      ].join(' ')}
    >
      {/* <div
        className={[styles.prev_adapt_file_wrapper, 'hide'].join(' ')}
        onClick={async () => {
          if (
            // data?.course_state?.toLowerCase() === "live" ||
            // data?.courseState?.toLowerCase() === "live" ||
            uploadingFileButton
          ) {
            return;
          } else {
            await previewAdaptFileCreateNewCourse();
            // setOpenPreviewAdaptFile(true);
            // adaptFileState === "SUCCESS" && openInNewTab();
          }
        }}
      >
        <div
          className={
            (previewData ||
              (data && data?.adaptFileName?.length > 0) ||
              (fileData && fileData?.name?.length > 0)) &&
            !uploadingFileButton
              ? styles.txt
              : [styles.txt, styles.txt_disable].join(' ')
          }
        >
          Preview Course File
        </div>
        <div className={[styles.adapt_file_icon].join(' ')}></div>
      </div> */}

      <div className={styles.row_wrapper}>
        {openAdaptFileModal && (
          <AdaptFileModalComponent
            unzipState={adaptFileState}
            closeModal={() => {
              setOpenAdaptFileModal(false);
              //fetchSelectedCourse(data?.courseId);
            }}
            localState={configData?.primaryLanguage}
            openPreviewFile={() => {
              openInNewTab();
              //fetchSelectedCourse(data?.courseId);
              // updateCourseDetails(data?.courseId,{adaptFileName:fileData?.name,courseState:data?.courseState
              //   //  ,version :versionNum
              // })
              // })
              localState == configData?.locale;
            }}
          />
        )}
        {/* {openAdaptFileModal && (
          <AdaptFileModalComponent
            unzipState={adaptFileState}
            closeModal={() => {
              setOpenAdaptFileModal(false);
              //fetchSelectedCourse(data?.courseId);
            }}
            localState={localState == configData?.primaryLanguage}
            openPreviewFile={() => {
              openInNewTab();
              //fetchSelectedCourse(data?.courseId);
              // updateCourseDetails(data?.courseId, {
              //   adaptFileName: fileData?.name,
              //   //  ,version :versionNum
              // });
              fileDataTemp
                ? setFileData(fileDataTemp)
                : fileDataTempHindi
                ? setFileData(fileDataTempHindi)
                : null;
            }}
          />
        )} */}
        {/* Info container block 1st Section -- Course Info,Thumbnail Image  */}
        <div className={styles.info_container}>
          {/* Left Section */}
          <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.info_icon].join(' ')}></span>
                <span>Course Information ({configData?.primaryLanguage})*</span>
              </div>
              <div className={[styles.txt, styles.margin_zero].join(' ')}>
                Enter Course Information in primary language to enable Uploading Course file.
                <div className={styles.note_text}>
                  Note: Title, Description, Thumbnail Image and Course File are mandatory to save
                  the Course in Draft state and to move forward in the Create Course wizard.
                </div>
              </div>
            </div>

            {/* 1.1. Course Title  */}
            <div
              className={[
                styles.form_field,
                styles.info_wrapper,
                data?.primaryTitle?.length > 100 ? styles.error_wrapper_max_char : ' ',
              ].join(' ')}
            >
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>
                  Course Title*
                  {/* <span className={styles.astric}>*</span> */}
                </div>
                <div className={styles.tag_text}>Max 100 characters</div>
              </div>
              <div className={styles.input_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.input} />
                ) : (
                  <input
                    type={'text'}
                    name="primaryTitle"
                    // maxLength="100"
                    placeholder={'Enter Course Title'}
                    value={data && data?.primaryTitle}
                    className={styles.input}
                    // pattern={regexNameChinese}
                    onChange={(e) => {
                      e.target.value.length === 0 && handleDataFill(e.target.name, '');
                      handleDataFill(e.target.name, e.target.value?.toString());
                    }}
                  />
                )}
                {data?.primaryTitle?.length > 100 || isDuplicateCourseName ? (
                  <div className={styles.error_icon_red}></div>
                ) : null}
              </div>
              {data?.primaryTitle?.length > 100 ? (
                <div className={styles.error_message}>
                  {/* Need 3 or more characters to Create New Course */}
                  Maximum character limit reached
                </div>
              ) : null}

              {isDuplicateCourseName && (
                <div className={styles.error_message}>
                  The course name already exists. Please choose a different name.
                </div>
              )}
            </div>
            {/* 1.1. Course Title  */}

            {/* 1.2. Description */}
            <div
              className={[
                styles.form_field,
                styles.info_wrapper,
                data?.primaryDescription?.length > 500 ? styles.error_wrapper_max_char : ' ',
              ].join(' ')}
            >
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Description*</div>
                <div className={styles.tag_text}>Max 500 characters</div>
              </div>
              <div className={styles.input_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.textarea} />
                ) : (
                  <textarea
                    type={'text'}
                    // maxLength="500"
                    name="primaryDescription"
                    value={data && data?.primaryDescription}
                    placeholder={'Enter Course Description'}
                    className={styles.textarea}
                    onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                  />
                )}
                {data?.primaryDescription?.length > 500 ? (
                  <div className={styles.error_icon_red}></div>
                ) : null}
              </div>
              {data?.primaryDescription?.length > 500 ? (
                <div className={styles.error_message}>
                  {/* Need 3 or more characters to Create New Course */}
                  Maximum character limit reached
                </div>
              ) : null}
            </div>
            {/* 1.2. Description */}

            {/* 1.3 Author/speaker name  */}
            <div
              className={[
                styles.form_field,
                styles.info_wrapper,
                data?.primarySpeaker?.length > 30 ? styles.error_wrapper_max_char : ' ',
              ].join(' ')}
            >
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>
                  Course Author/Speaker Name ({configData?.primaryLanguage})
                </div>
                <div className={styles.tag_text}>Max 30 characters</div>
              </div>

              <div className={styles.txt}>
                Enter the Author or a Speaker Name for this Course. This information is visible to
                the Front End ABO besides the title of ‘Taught by’.
              </div>
              <div className={styles.input_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.input} />
                ) : (
                  <input
                    type={'text'}
                    name="primarySpeaker"
                    maxLength="31"
                    placeholder={'Enter Course Author/Speaker Name'}
                    value={data && data?.primarySpeaker}
                    className={styles.input}
                    onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                  />
                )}
                {data?.primarySpeaker?.length > 30 ? (
                  <div className={styles.error_icon_red}></div>
                ) : null}
              </div>
              {data?.primarySpeaker?.length > 30 ? (
                <div className={styles.error_message}>
                  {/* Need 3 or more characters to Create New Course */}
                  Maximum character limit reached
                </div>
              ) : null}
            </div>
            {/* 1.3 Author/speaker name  */}

            {/* not required */}
            {/* <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>English Course Author / Speaker Name</div>
              </div>
              <div className={styles.input_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.input} />
                ) : (
                  <input
                    type={'text'}
                    name="speaker"
                    maxLength="30"
                    placeholder={'पाठ्यक्रम लेखक/वक्ता का नाम दर्ज करें'}
                    defaultValue={
                      data &&
                      data?.multilingualDetails !== undefined &&
                      data?.multilingualDetails[0] !== null
                        ? data?.multilingualDetails[0]?.speaker
                            ?.toString()
                            ?.replaceAll(regexNameHindi, '')
                        : ''
                    }
                    value={
                      data &&
                      data?.multilingualDetails !== undefined &&
                      data?.multilingualDetails[0] !== null
                        ? data?.multilingualDetails[0]?.speaker
                            ?.toString()
                            ?.replaceAll(regexNameHindi, '')
                        : ''
                    }
                    className={styles.input}
                    onChange={(e) =>
                      handleDataFillMultiLingual(e.target.name, e.target.value, data)
                    }
                  />
                )}
              </div>
            </div> */}
            {/* not required */}
          </div>
          {/* left Section  */}

          {/* Right Section */}
          <div
            className={[styles.inr_wrapper, styles.thumbnail_wrapper, 'col-md-12 col-lg-6'].join(
              ' ',
            )}
          >
            {/* 1.1. Thumbnail Image */}
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.thumbnail_icon].join(' ')}></span>
                <span>Thumbnail Image*</span>
              </div>
            </div>
            {!mainImageBackup && !isLoading ? (
              <div
                className={[
                  styles.pre_browse_wrapper,
                  uploadingFileButton ? styles.disabled : '',
                  styles.uploadig_state,
                  '',
                ].join(' ')}
              >
                <div className={styles.browse_img_txt_wrapper}>
                  <div className={styles.browse_img}></div>
                  <div
                    className={styles.browse_text}
                    onClick={() => {
                      if (!uploadingFileButton) {
                        setCourseMode('FROM_COURSE_WIZARD');
                        setLearningPath('');
                        router.push('/Contentmanagement');
                        // setOpenImageLib();
                        setNewCourseData({
                          data: data,
                          adaptFile: fileDataTemp,
                          skills: pillsDataSkills,
                          keyword: pillsDataKeyword,
                          newCourseID: newCourseID,
                          categoryData: categoryData,
                          pillsAddAudienceGroup: pillsAddAudienceGroup,
                          isPreviewAdaptFile: publishButtonAfterPreview,
                          addUserGroupList: addUserGroupList,
                          addUserGroupListCopy: addUserGroupListCopy,
                          deleteUserGroupList: deleteUserGroupList,
                        });
                        //setNewAdaptFile(fileData);
                        setNewCourseWizardState({
                          courseWizardOpen: true,
                          courseWizardStateOpen: 'edit',
                          section: '',
                        });
                      }
                      if (uploadingFileButton || uploadingFileButtonHindi) {
                        disablehostingBanner();
                      }
                      setThumbnailImage169(null);
                    }}
                  >
                    Choose Image from Image Library
                  </div>
                </div>
                <div className={styles.text}>or</div>
                <input
                  type={'file'}
                  accept=".jpg,.jpeg"
                  onChange={(event) => {
                    if (!uploadingFileButton) {
                      setCourseMode('FROM_COURSE_WIZARD');
                      setLearningPath('');
                      setImageData({
                        imageName: null,
                        imageDescription: null,
                        pillsData: null,
                      });
                      uploadImageFromDesktop(event, event.target.files[0]);
                      setNewCourseData({
                        data: data,
                        adaptFile: fileDataTemp,
                        skills: pillsDataSkills,
                        keyword: pillsDataKeyword,
                        newCourseID: newCourseID,
                        categoryData: categoryData,
                        pillsAddAudienceGroup: pillsAddAudienceGroup,
                        isPreviewAdaptFile: publishButtonAfterPreview,
                        addUserGroupList: addUserGroupList,
                        addUserGroupListCopy: addUserGroupListCopy,
                        deleteUserGroupList: deleteUserGroupList,
                      });
                      // setNewAdaptFile(fileData);
                      setNewCourseWizardState({
                        courseWizardOpen: true,
                        courseWizardStateOpen: 'edit',
                        section: '',
                      });
                    }
                    if (uploadingFileButton || uploadingFileButtonHindi) {
                      disablehostingBanner();
                    }
                  }}
                  hidden={true}
                  multiple={false}
                  id={'chooseImageEdit'}
                />
                <label htmlFor="chooseImageEdit">
                  <div className={[styles.browse_text, styles.secondary_text].join(' ')}>
                    Choose Image from Local Device
                  </div>
                </label>
              </div>
            ) : mainImageBackup && !isLoading ? (
              <div className={[styles.thumbnail_inner_wrapper, ''].join(' ')}>
                <div className={[styles.top_section, ' '].join(' ')}>
                  <div className={[styles.btn_wrapper_uaf, styles.margin_zero].join(' ')}>
                    <div
                      className={styles.txt}
                      onClick={() => {
                        if (!uploadingFileButton) {
                          setCourseMode('FROM_COURSE_WIZARD');
                          setLearningPath('');
                          setImageData({
                            imageName: null,
                            imageDescription: null,
                            pillsData: null,
                          });
                          // setOpenImageLib();
                          router.push('/Contentmanagement');
                          setNewCourseData({
                            data: data,
                            adaptFile: fileDataTemp,
                            skills: pillsDataSkills,
                            keyword: pillsDataKeyword,
                            newCourseID: newCourseID,
                            categoryData: categoryData,
                            pillsAddAudienceGroup: pillsAddAudienceGroup,
                            isPreviewAdaptFile: publishButtonAfterPreview,
                            addUserGroupList: addUserGroupList,
                            addUserGroupListCopy: addUserGroupListCopy,
                            deleteUserGroupList: deleteUserGroupList,
                          });
                          //setNewAdaptFile(fileData);
                          setNewCourseWizardState({
                            courseWizardOpen: true,
                            courseWizardStateOpen: 'edit',
                            section: '',
                          });
                        }
                        if (uploadingFileButton || uploadingFileButtonHindi) {
                          disablehostingBanner();
                        }
                      }}
                    >
                      Choose From Image Library
                    </div>
                    <div className={styles.book_icon}></div>
                  </div>
                  <div
                    className={styles.insturction_text_wrapper}
                    onClick={() => {
                      if (!uploadingFileButton) {
                        //setNewImageData(null);
                        setCourseMode('FROM_COURSE_WIZARD');
                        setLearningPath('');
                        uploadNewImagePath();
                        setNewCourseData({
                          data: data,
                          adaptFile: fileDataTemp,
                          skills: pillsDataSkills,
                          keyword: pillsDataKeyword,
                          newCourseID: newCourseID,
                          categoryData: categoryData,
                          pillsAddAudienceGroup: pillsAddAudienceGroup,
                          isPreviewAdaptFile: publishButtonAfterPreview,
                          addUserGroupList: addUserGroupList,
                          addUserGroupListCopy: addUserGroupListCopy,
                          deleteUserGroupList: deleteUserGroupList,
                        });
                        setNewThumbnailImageData(mainImageBackup);
                        //setNewAdaptFile(fileData);
                        setNewCourseWizardState({
                          courseWizardOpen: true,
                          courseWizardStateOpen: 'edit',
                          section: '',
                        });
                      }
                      if (uploadingFileButton || uploadingFileButtonHindi) {
                        disablehostingBanner();
                      }
                    }}
                  >
                    <div className={styles.info_text}>Upload New Image </div>
                    <div className={styles.left_arrow_icon}></div>
                  </div>
                </div>
                <div className={[styles.img_wrapper, ' '].join(' ')}>
                  <div className={styles.img}>
                    <img
                      // src={ImageDetail?.bannerImage ? ImageDetail.bannerImage : ""}
                      src={
                        //newThumbnailId?.length > 0 &&
                        thumbnailImage169?.img ? thumbnailImage169?.img : mainImageBackup
                      }
                      alt="thumbnail"
                      className={styles.selected_img}
                    />
                  </div>
                </div>
              </div>
            ) : isLoading ? (
              <LoadingShimmerCustom className={styles.pre_browse_wrapper} />
            ) : null}
            <div className={styles.tag_text}> 3MB max | .jpg, jpeg</div>
            {/* 1.1. Thumbnail Image */}
          </div>
          {/* Right Section */}
        </div>
        {/* Info container block 2nd section -- Course Runtime, Course Content, Achievement, Downloads*/}
        <div className={[styles.info_container, styles.course_adapt_wrapper_header, ''].join(' ')}>
          {/* Left Section --> Course Runtime, Course Content  */}
          <div
            className={[
              styles.inr_wrapper,
              styles.thumbnail_wrapper,
              styles.course_adapt_inner_wrapper,
              'col-md-12 col-lg-6',
            ].join(' ')}
          >
            {/* 2.1. Course Runtime */}
            <div className={styles.section_margin}>
              <div className={styles.title_wrapper}>
                <div className={styles.title}>
                  <span className={[styles.icon, styles.timer_icon].join(' ')}></span>
                  <span>Course Runtime</span>
                </div>
              </div>
              <div className={styles.course_time_wrapper}>
                <div className={styles.label_wrapper}>
                  <div className={styles.label_text}>Total Course Runtime*</div>
                </div>
                <div className={styles.time_spend_wrapper}>
                  {isLoading ? (
                    <LoadingShimmerCustom className={styles.hrs_box} />
                  ) : (
                    <input
                      type={'number'}
                      name="duration"
                      defaultValue={data?.duration === 0 ? '' : data && Number(data?.duration)}
                      value={data?.duration === 0 ? '' : data && Number(data?.duration)}
                      placeholder="00"
                      min="0"
                      max="20000"
                      className={styles.hrs_box}
                      onChange={(e) => handleDataFill(e.target.name, Number(e.target.value))}
                      onFocus={(e) => (e.target.placeholder = '')}
                      onBlur={(e) => (e.target.placeholder = '0')}
                    />
                  )}
                  <div className={styles.txt_xs}>Minutes</div>
                </div>
              </div>
            </div>
            {/* 2.1. Course Runtime */}

            {/* 2.2. Course Content  */}
            <div className={styles.course_content}>
              <div className={styles.title_wrapper}>
                <div className={[styles.title, styles.title_black].join(' ')}>Course Content</div>
              </div>
              <div className={styles.course_content_header_wrapper}>
                <div className={styles.label_wrapper}>
                  <div className={styles.label_text}>Course File*</div>
                </div>
                <div className={styles.tag_text}>200MB max | .zip</div>
              </div>
              <div className={styles.sub_txt}>
                Replacing Course File is a great way to make minor content tweaks, or fix spelling
                errors. Course file can only be updated while the course is offline. This process
                happens separately from changes made to the rest of the course details page.
                <div className={styles.bold}>
                  {' '}
                  Once an Course file is replaced, the old one is deleted and cannot be undone by
                  clicking on ‘Cancel’ button.
                </div>
              </div>
              {/* Course file */}
              <input
                type={'file'}
                // accept=".zip,.rar,.7z"
                accept=".zip"
                hidden={true}
                multiple={false}
                id={'uploadFileEdit'}
                onChange={(event) => {
                  handleFileUpload(
                    event,
                    data?.id || data?.courseId,
                    configData?.locale,
                    data?.version ? data.version : 0,
                  );
                }}
                disabled={
                  data?.course_state?.toLowerCase() === 'live' ||
                  data?.courseState?.toLowerCase() === 'live' ||
                  adaptFileButtonState ||
                  uploadingFileButton ||
                  uploadingFileButtonHindi
                }
              />

              <label
                htmlFor="uploadFileEdit"
                style={{
                  display: 'block',
                  width: '100%',
                  marginBottom: '0',
                }}
                //  style={{pointerEvents: !isCourseIDFetched ? 'none' : ''}}
              >
                <div
                  className={
                    uploadingFileButton &&
                    (data?.course_state?.toLowerCase() !== 'live' ||
                      data?.courseState?.toLowerCase() !== 'live')
                      ? [styles.btn_wrapper_uaf, styles.inprogress_state].join(' ')
                      : (fileDataTemp?.name || data?.adaptFileName || isCourseIDFetched) &&
                        data?.course_state?.toLowerCase() !== 'live' &&
                        data?.courseState?.toLowerCase() !== 'live' &&
                        !uploadingFileButtonHindi
                      ? [styles.btn_wrapper_uaf, styles.final_state].join(' ')
                      : styles.btn_wrapper_uaf
                  }
                  onClick={() => {
                    if (
                      data?.course_state?.toLowerCase() === 'live' ||
                      data?.courseState?.toLowerCase() === 'live'
                    ) {
                      disabledEditBannerMessage();
                    }
                    if (uploadingFileButtonHindi) {
                      disablehostingBanner();
                    }

                    setLocalState(configData?.locale);
                  }}
                >
                  <div
                    className={styles.txt}
                    style={{
                      minHeight: 'fit-content',
                      marginBottom: '0',
                    }}
                  >
                    {uploadingFileButton && !replaceFile
                      ? 'Course File Uploading'
                      : uploadingFileButton && replaceFile
                      ? 'File Replacing'
                      : data?.adaptFileName
                      ? 'Replace Course File'
                      : 'Upload Course File'}
                  </div>
                  <div className={styles.upload_icon}></div>
                </div>

                {uploadingFileButton && (
                  <div className={styles.note_text}>
                    Note: Please do not navigate to any other page when the file is uploading.
                  </div>
                )}
              </label>
              {adaptFileState === 'FAILURE' ? (
                 <div className={styles.hosting_banner}>
                 <div className={`${styles.error_bar} ${styles.failure_bar}`}>
                   <div className={`${styles.left_icon} ${styles.failure_tick_icon}`} />
                   <div className={`${styles.text_wrapper} ${styles.failure_txt}`}>
                     <div className={styles.tagline}>{'File Hosting Failed'}</div>
                     <div
                       className={styles.description}
                     >{`Please Upload the Course file again.(ie: missing ${missingFilesMessage} and etc)`}</div>
                   </div>
                 </div>
               </div>
              ):(
                data?.adaptFileName ? (
                <div className={[styles.attachement_wrapper, ''].join(' ')}>
                  <div className={[styles.txt, styles.prev_adapt_file_name].join(' ')}>
                    {data?.adaptFileName}
                  </div>
                  <div
                    className={[styles.txt, styles.preview_adapt_text, styles.bold].join(' ')}
                    onClick={async () => {
                      await previewAdaptFileCreateNewCourse(configData?.locale);
                    }}
                  >
                    Preview File
                    <div className={styles.left_arrow_icon} />
                  </div>
                </div>
              ) : (
                <div className={[styles.tag_text, styles.padding_zero].join(' ')}>
                  {' '}
                  200MB max | .zip
                </div>
              ))
              }
              <div className={styles.warning_wrapper}>
                <div className={styles.warning_txt}>
                  To ensure a positive user experience for our ABOs, please refrain from adding or
                  removing sections when replacing Course Files. This can cause issues. We suggest
                  creating a new course using the updated file instead of modifying the existing
                  one.
                </div>
              </div>

             {/* Course Code Start */}
              <div className={[styles.inr_wrapper, 'p-0 mt-4'].join(' ')}>
                <div
                  className={[
                    styles.form_field,
                    styles.info_wrapper,
                    (courseCodeErrorMessage?.length > 0)
                      ? styles.error_wrapper_max_char
                      : ' ',
                  ].join(' ')}
                >
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Course Code</div>
                    <div className={styles.tag_text}>Max 60 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.input} />
                    ) : (
                      <input
                        type={'text'}
                        placeholder={'Enter Course Title'}
                        className={styles.input}
                        defaultValue={data && data?.contentCode}
                        value={data && data?.contentCode}
                        name="contentCode"
                        maxLength="61"
                        onChange={(e) => {
                          // e.target.value.length === 0 && handleDataFill(e.target.name, '');
                          // handleDataFill(e.target.name, e.target.value);
                          e.target.value.length === 0 &&
                            handleDataFill(e.target.name?.normalize('NFC'), '');

                          handleDataFill(
                            e?.target?.name?.normalize('NFC'),
                            e?.target?.value?.toString(),
                          );
                        }}
                      />
                    )}
                    {courseCodeErrorMessage?.length > 0 ? <div className={styles.error_icon_red}></div> : null}
                  </div>

                  {courseCodeErrorMessage?.length > 0 ? (
                    <div className={styles.error_message}>{courseCodeErrorMessage}</div>
                  ) : null}
                  
                </div>
              </div>
              {/* Course Code End */}

            </div>
            {/* 2.2. Course Content  */}
          </div>

          

          {/* Right Section --> Achivements,Downloads  */}
          <div
            className={[
              styles.inr_wrapper,
              styles.thumbnail_wrapper,
              styles.course_adapt_inner_wrapper,
              'col-md-12 col-lg-6',
            ].join(' ')}
          >
            {/* 2.1.Achivement Section */}
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.achievements_icon].join(' ')}></span>
                <span>Achievements</span>
              </div>
              <div className={styles.txt}>
                Add an ‘Award of Completion’ to this Course to reward the ABO. They will get an
                automatically generated Award with the Course Name, ABO Name, and Date of
                Completion.
                <div className={styles.note_txt}>
                  Note: Award addition to a Course is only possible during creation of a Course. It
                  cannot be added or removed later.
                </div>
              </div>
            </div>
            <div className={`${styles.certificate_wrapper}`}>
              {isLoading ? (
                <div className={styles.checkbox_container}>
                  {' '}
                  <div className={styles.outerDiv}>
                    {' '}
                    <LoadingShimmerCustom className={styles.achievement_section} />
                  </div>{' '}
                  <div className={styles.disable}>
                    ABOs recieve an Award for completing this course.
                  </div>{' '}
                </div>
              ) : (
                <div
                  className={`${styles.checkbox_container} ${
                    data?.courseState?.toLowerCase() === 'live' ||
                    data?.courseState?.toLowerCase() === 'offline'
                      ? styles.disabled
                      : ''
                  }`}
                >
                  {/* <CheckboxComponent
                    checked={data && data?.isCertificateAvailable}
                    checkFunc={() => {
                      data && data?.isCertificateAvailable
                        ? handleDataFill("isCertificateAvailable", Boolean(false))
                        : handleDataFill("isCertificateAvailable", Boolean(true));
                    }}
                  /> */}
                  <CheckboxNew
                    isChecked={data?.isCertificateAvailable}
                    handleCheckClick={() => {
                      data && data?.isCertificateAvailable
                        ? handleDataFill('isCertificateAvailable', Boolean(false))
                        : handleDataFill('isCertificateAvailable', Boolean(true));
                    }}
                    isDisabled={false}
                    // label={
                    //   "ABOs recieve a Certificate for completing this course."
                    // }
                  />
                  <div className={styles.disable}>
                    ABOs receive an Award for completing this Course.
                  </div>
                </div>
              )}
            </div>
            {/* 2.1.Achivement Section */}

            {/* 2.2. Download Section  */}
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.achievements_icon].join(' ')}></span>
                <span>Downloads</span>
              </div>
              <div className={styles.sub_title}>Downloads (Creators+ Only)</div>
              <div className={styles.txt}>
                By checking below, you allow the ABOs to download the course and take it Offline.
                Downloading is unavailable in the web version of Academy.
              </div>
            </div>
            <div className={styles.certificate_wrapper}>
              {isLoading ? (
                <div className={styles.checkbox_container}>
                  {' '}
                  <div className={styles.outerDiv}>
                    {' '}
                    <LoadingShimmerCustom className={styles.achievement_section} />
                  </div>{' '}
                  <div className={styles.disable}>ABOs can download the Course.</div>{' '}
                </div>
              ) : (
                <div className={styles.checkbox_container}>
                  {/* <CheckboxComponent
                    checked={data && data?.isCertificateAvailable}
                    checkFunc={() => {
                      data && data?.isCertificateAvailable
                        ? handleDataFill("isCertificateAvailable", Boolean(false))
                        : handleDataFill("isCertificateAvailable", Boolean(true));
                    }}
                  /> */}
                  <CheckboxNew
                    isChecked={data?.isDownloadable}
                    handleCheckClick={() => {
                      data && data?.isDownloadable
                        ? handleDataFill('isDownloadable', Boolean(false))
                        : handleDataFill('isDownloadable', Boolean(true));
                    }}
                    isDisabled={false}
                    // label={
                    //   "ABOs recieve a Certificate for completing this course."
                    // }
                  />
                  <div className={styles.disable}>ABOs can download the Course.</div>
                </div>
              )}
            </div>
            {/* 2.2. Download Section  */}
          </div>
        </div>

        {/* Translation Model */}
        <div className={styles.info_container}>
          <div className={styles.translation_cards}>
            {Array.isArray(data.multilingualDetails) && (
              <TranslationEditLanguage
                isLp={false}
                learningPathId={query.id}
                translationFor="course wizard"
                placeholders={{
                  dropdown: 'Select Language',
                  title: 'Enter Course Title',
                  author: 'Enter Author/Speaker Name',
                  description: 'Enter Course Description',
                }}
                courseData={data}
                disabled={
                  data?.course_state?.toLowerCase() === 'live' ||
                  data?.courseState?.toLowerCase() === 'live'
                }
                previewAdaptFileCreateNewCourse={previewAdaptFileCreateNewCourse}
                disabledEditBannerMessage={disabledEditBannerMessage}
                isEditCourse={true}
              />
            )}
          </div>
        </div>
        {/* Translation Model */}

        {/* Info container block  3rd Section*/}
        <div className={styles.info_container}>
          {/* Left Section  */}
          {/* 3.1. User Group Targetting */}
          <div
            className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(' ')}
          >
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.user_icon].join(' ')}></span>
                <span>User Group Targeting</span>
              </div>
              <div className={styles.txt}>
                Target this course to the User Groups mentioned below. If you do not want this
                course to be accessible to a particular audience group(s), you can remove them from
                the course accesibility list. You can specify the Course type by selecting from the
                options given below for each User Group.{' '}
                {remoteConfig?.user_group_country_targeting ? (
                  <div>
                    <b>
                      Note: At least one country must be selected in Country Availability to move
                      forward in the Create Course wizard.{' '}
                    </b>
                  </div>
                ) : null}
              </div>
            </div>

            {remoteConfig?.user_group_country_targeting ? (
              <CountrySelection
                countryList={countryList}
                handleClearAll={handleCountryClearAll}
                handleSelectAll={handleCountrySelectAll}
                handleCountrySelect={handleCountrySelectA}
                isLive={data?.courseState === 'Live' ? true : false}
              ></CountrySelection>
            ) : null}

            <div className={styles.card_list_wrapper}>
              {addUserGroupList?.length == 0 && deleteUserGroupList?.length > 0 && (
                <div className={styles.note_wrapper}>
                  <NoteCardComponent />
                </div>
              )}
              {addUserGroupList?.length > 0 && (
                <div className={styles.title}>User Groups with Course Access</div>
              )}
              <div className={styles.card_wrapper}>
                {!isLoading &&
                  addUserGroupList?.map((element, index) => {
                    return (
                      <CardNewComponent
                        key={index}
                        ugCourseTitle={element?.title}
                        id={element?.id || element?.audGrpId}
                        isDisabled={disableStateUserManagement}
                        onHandleClickRadio={(ele, id) => {
                          handleDeleteUserTargetingGroups(
                            {
                              courseType: ele,
                              id: element?.id || element?.audGrpId,
                              title: element?.title,
                              audienceGrpDescription:
                                element?.audienceGrpDescription || element.description,
                            },
                            addUserGroupList,
                          );
                        }}
                        courseType={element?.courseType}
                        onButtonClick={() => {
                          handleDeleteUserGroups({
                            courseType: element?.courseType,
                            id: element?.id || element?.audGrpId,
                            title: element?.title,
                            audienceGrpDescription:
                              element?.audienceGrpDescription || element.description,
                          });
                        }}
                        disableClickBanner={() => {
                          disableClickBanner();
                          if (uploadingFileButton || uploadingFileButtonHindi) {
                            disablehostingBanner();
                          }
                        }}
                        bonusIcon={true}
                        audienceGrpDescription={element?.audienceGrpDescription}
                      />
                    );
                  })}
                {isLoading ? getShimmerList(5) : null}
              </div>
            </div>
            <div className={styles.card_list_wrapper}>
              {deleteUserGroupList?.length > 0 && (
                <div className={styles.title}>User Groups without Course Access</div>
              )}
              <div className={styles.card_wrapper}>
                {!isLoading &&
                  deleteUserGroupList?.map((element, index) => {
                    return (
                      <CardNewComponent
                        key={index}
                        withoutCourses={true}
                        ugCourseTitle={element?.title}
                        id={element?.id || element?.audGrpId}
                        isDisabled={disableStateUserManagement}
                        onHandleClickRadio={(ele, id) => {
                          handleDeleteUserTargetingGroups({
                            courseType: ele,
                            id: element?.id || element?.audGrpId,
                            title: element?.title,
                            audienceGrpDescription:
                              element?.audienceGrpDescription || element.description,
                          }),
                            deleteUserGroupList;
                        }}
                        courseType={element?.courseType}
                        onButtonClick={() => {
                          handleAddUserTargetingGroups({
                            courseType: 'General visibility',
                            id: element?.id || element?.audGrpId,
                            title: element?.title,
                            audienceGrpDescription:
                              element?.audienceGrpDescription || element.description,
                          });
                        }}
                        disableClickBanner={() => {
                          disableClickBanner();
                          if (uploadingFileButton || uploadingFileButtonHindi) {
                            disablehostingBanner();
                          }
                        }}
                        bonusIcon={true}
                        audienceGrpDescription={element?.audienceGrpDescription}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          {/* Right Section  */}
          <div
            className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(' ')}
          >
            {/* 3.1. category */}
            <div className={styles.section_margin}>
              <div className={styles.title_wrapper}>
                <div className={styles.title}>
                  <span className={[styles.icon, styles.category_icon].join(' ')}></span>
                  <span>Category*</span>
                </div>
                <div className={styles.txt}>
                  All Courses must have a Category. Only one Category may be selected. Categories
                  are used in Explore by the ABO to filter through courses. They are also used by
                  the system when populating related courses.
                </div>
              </div>
              <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                <div className={[styles.input_wrapper, styles.input_capitalize].join(' ')}>
                  {isLoading ? (
                    <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
                  ) : (
                    <SearchListComponent
                      list={categories}
                      varient="dropdown"
                      defaultInputData={categoryData ? categoryData : ''}
                      handlePillList={(title, dataWithTitle) => {
                        //setPillsData(data);
                        setCategoryData(title);
                        handleDataFill('category', dataWithTitle);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* 3.2. Skills you'll gain  */}
            <div className={styles.section_margin}>
              <div className={styles.title_wrapper}>
                <div className={styles.title}>
                  <span className={[styles.icon, styles.skills_icon].join(' ')}></span>
                  <span>Skills You Will Gain</span>
                </div>
                <div className={styles.txt}>
                  Choose from a pre-loaded list of Skills that aid the user in choosing which
                  Courses to take. Skills appear on the Course details drawer for front-end app
                  users. Skills are automatically translated into all language supported by your
                  market. You may select up to 5 skills to display on a Course.
                </div>
              </div>
              <div
                className={[styles.form_field, styles.info_wrapper, styles.margin_zero].join(' ')}
              >
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
                ) : (
                  <div className={styles.input_wrapper} ref={skillsRef}>
                    <SearchListComponent
                      list={skills}
                      reference={skillsRef}
                      varient="textInput"
                      placeHolderText={
                        data?.skills?.length >= 5
                          ? 'All Skills Added'
                          : 'Enter Skills You Will Gain'
                      }
                      //defaultInputData={data && data.category}
                      pillsLimit={5}
                      handlePillList={(data, dataWithTitle) => {
                        setPillsDataSkills(data);
                        handleDataFill('skills', dataWithTitle);
                      }}
                      defaultPillList={pillsDataSkills}
                      disabledInput={data?.skills?.length >= 5}
                    />
                    <div className={styles.count_num}>{`${
                      pillsDataSkills?.length ? pillsDataSkills?.length : '0'
                    }/5`}</div>
                  </div>
                )}
              </div>
              {!isLoading && (
                <div className={[styles.pills_container, 'col-xl-12 col-lg-12 '].join(' ')}>
                  <Pills
                    pillData={pillsDataSkills}
                    isClose={true}
                    isBold={false}
                    onClosePill={(item) => {
                      filterListById(
                        item.id,
                        pillsDataSkills,
                        setPillsDataSkills,
                        'skills',
                        handleDataFill,
                      );
                    }}
                  />
                </div>
              )}
            </div>

            {/* 3.3. Keyword Tagging */}
            <div className={styles.section_margin}>
              <div className={styles.title_wrapper}>
                <div className={styles.title}>
                  <span className={[styles.icon, styles.tag_icon].join(' ')}></span>
                  <span>Keyword Tagging</span>
                </div>
                <div className={styles.txt}>
                  Keyword Tags are used by the Academy to aid in search and filtering both for
                  front-end app users. These keywords do not appear on course detail drawers. Add
                  all relevant tags to the course.
                </div>
              </div>
              <div
                className={[styles.form_field, styles.info_wrapper, styles.margin_zero].join(' ')}
                ref={keywordsRef}
              >
                <div className={styles.label_wrapper}>
                  <div className={styles.label_text}>Add Tags</div>
                </div>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
                ) : (
                  <SearchListComponent
                    list={keyword}
                    reference={keywordsRef}
                    varient="textInput"
                    placeHolderText="Add Keywords and Enter"
                    //defaultInputData={data && data.category}
                    handlePillList={(data, dataWithTitle) => {
                      setPillsDataKeyword(data);
                      handleDataFill('keywords', dataWithTitle);
                    }}
                    pillsLimit={100}
                    defaultPillList={pillsDataKeyword}
                  />
                )}
              </div>
              {!isLoading && (
                <div className={[styles.pills_container, 'col-xl-12 col-lg-12'].join(' ')}>
                  <Pills
                    pillData={pillsDataKeyword}
                    isClose={true}
                    isBold={false}
                    onClosePill={(item) => {
                      filterListById(
                        item.id,
                        pillsDataKeyword,
                        setPillsDataKeyword,
                        'keywords',
                        handleDataFill,
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {/* Required DropDown Category  */}
        </div>

        {/* Commenting to resolve ABOACA-12865 */}
        {/* <div className={styles.info_container}>
          <div
            className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(' ')}
          ></div>
        </div> */}

        {/* Info container block */}
        <div className={styles.info_container}>
          <div
            className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(' ')}
          >
            <div
              className={styles.title_wrapper}
              onClick={() => {
                !uploadingFileButton && setOpenDecommission(!openDecommission);
              }}
            >
              <div className={styles.title}>
                <span className={[styles.icon, styles.warn_icon].join(' ')}></span>
                <span>Decommission Course</span>
                <div
                  className={
                    openDecommission
                      ? [styles.arw_blue_icon, styles.is_decommission_open].join(' ')
                      : styles.arw_blue_icon
                  }
                ></div>
              </div>

              {openDecommission && (
                <div className={styles.txt}>
                  This will permanently remove a course from the Academy App. Users who have taken
                  the course will still see the archived version in their Completed Courses section
                  but may not re-take it. No user can start the course once archived. This action
                  cannot be undone.
                </div>
              )}
            </div>
            {openDecommission && !isLoading && (
              <div
                className={[styles.btn_wrapper_uaf, styles.btn_wrapper_dec].join(' ')}
                onClick={() => setIsArchive(true)}
              >
                <div className={[styles.icon, styles.gift_icon].join(' ')}></div>
                <div className={styles.txt}>Archive This Course</div>
              </div>
            )}
            {openDecommission && isLoading ? (
              <LoadingShimmerCustom
                className={[styles.btn_wrapper_uaf, styles.btn_wrapper_dec].join(' ')}
              />
            ) : null}
          </div>
        </div>
      </div>
      {isArchive && (
        <DeleteModalComponent
          modalType={'ARCHIVE'}
          closeDeleteModal={() => setIsArchive(false)}
          saveDeleteChanges={async () => {
            await changeCourseStatus(data?.courseId, data, 'archive');
          }}
          name={
            data?.primaryTitle ? data.primaryTitle : data?.primaryTitle ? data.primaryTitle : ''
          }
          deleteCall={async () => {
            await changeCourseStatus(data?.courseId, data, 'archive');
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  countryList: state && state.uiState && state.uiState.countryList,
});

const mapDispatchToProps = (dispatch) => ({
  setCountryList: (options) => dispatch(setCountryList(options)),
  setLearningPath: (options) => dispatch(setLearningPath(options)),
});
export default React.memo(connect(mapStateToProps, mapDispatchToProps)(EditWizard));
